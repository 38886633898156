import { GAEvent } from '@/constants/gaevents';
import { useNotificationStatsResetMutation } from '@/graphql';
import useMediaQuery from '@/hooks/useMediaQuery';
import useDisableBodyScroll from '@/hooks/useDisableBodyScroll';
import { NotificationStatsTypeEnum } from '@/types/graphql';
import useAnalytics from '@/util/analytics';
import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useEffect, useState, useRef } from 'react';
import LoadingState from '../LoadingState';
import Modal from '@/components/Modal';
import NotificationListItem from './NotificationListItem';
import Card from '@/components/Card';
import SingleColumnLayout from '@/components/SingleColumnLayout';
import {
  CenterContainer,
  Container,
  ContainerBottomSheet,
  Divider,
  Header,
  HeaderTitle,
  RightIconContainer,
  Subtitle,
} from './styles';
import styled from '@/styles';

interface NotificationComponentProps {
  open: boolean;
  data: any;
  hideRedDot: () => void;
  loadMoreNotifications: () => void;
  loading: boolean;
  enableReadAll: boolean;
  readNotification: (id: string) => void;
  toggleNotification: () => void;
}

// Add a transparent Overlay component specifically for notifications
const TransparentOverlay = styled('div', {
  bottom: 0,
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  backgroundColor: 'transparent',
  overflowX: 'hidden',
  overflowY: 'auto',
  zIndex: '$overlay',
});

const NotificationComponent = ({
  open,
  data,
  hideRedDot,
  loadMoreNotifications,
  enableReadAll,
  readNotification,
  loading,
  toggleNotification,
}: NotificationComponentProps) => {
  const [isOpen, setIsOpen] = useState(open);
  const [scrollDisabled, setScrollDisabled] = useState(open);
  const notificationRef = useRef<HTMLDivElement>(null);

  // Use the hook with our custom state
  useDisableBodyScroll(scrollDisabled);

  const [resetRead] = useNotificationStatsResetMutation({
    onCompleted() {
      hideRedDot();
    },
  });

  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const { logEvent } = useAnalytics();

  // Set up a click handler on the document to close the notification when clicking outside
  useEffect(() => {
    if (isOpen) {
      const handleClickOutside = (event: MouseEvent) => {
        // If the notification is open and the click is outside the notification content
        if (
          notificationRef.current &&
          !notificationRef.current.contains(event.target as Node) &&
          !event.defaultPrevented
        ) {
          handleClose();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isOpen]);

  useEffect(() => {
    if (open) {
      logEvent(GAEvent.NotificationPopupOpened);
    }
    setIsOpen(open);
    setScrollDisabled(open);
  }, [open]);

  const loadMore = (e) => {
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop <=
        e.currentTarget.clientHeight + 10 &&
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop >=
        e.currentTarget.clientHeight - 10;
    if (bottom) {
      loadMoreNotifications();
    }
  };

  // Custom function to close the notification popup properly
  const handleClose = () => {
    // First enable scrolling, then close the popup
    setScrollDisabled(false);
    toggleNotification();
  };

  const renderNotificationBottomSheet = () => {
    return (
      <TransparentOverlay>
        <div style={{ padding: '72px 16px' }}>
          <SingleColumnLayout>
            <Card
              ref={notificationRef}
              css={{ boxShadow: '$2' }}
              changeStyle={{ borderRadius: '10px' }}
            >
              <ContainerBottomSheet
                id="notification-popup"
                onScroll={(e) => {
                  loadMore(e);
                }}
              >
                <Header>
                  <HeaderTitle>Notifications</HeaderTitle>
                  <RightIconContainer>
                    <div
                      onClick={() => {
                        if (enableReadAll) resetUnreadCount();
                      }}
                    >
                      <DoneAllIcon htmlColor="#64748B" />
                    </div>
                    <div>
                      <CloseIcon htmlColor="#64748B" onClick={handleClose} />
                    </div>
                  </RightIconContainer>
                </Header>
                {Array.isArray(data) && data.length > 0 ? (
                  data.map((item, index) => {
                    return (
                      <NotificationListItem
                        hideRedDot={!enableReadAll}
                        item={item}
                        key={index}
                        updateNotification={readNotification}
                      />
                    );
                  })
                ) : (
                  <CenterContainer>
                    <HeaderTitle>{'No notifications available'}</HeaderTitle>
                  </CenterContainer>
                )}
                {loading && <LoadingState />}
              </ContainerBottomSheet>
            </Card>
          </SingleColumnLayout>
        </div>
      </TransparentOverlay>
    );
  };

  const resetUnreadCount = async () => {
    logEvent(GAEvent.NotificationMarkAllRead);
    await resetRead({
      variables: {
        notificationStatsType: NotificationStatsTypeEnum.RELEASE_NOTES,
      },
    });
  };

  if (!isOpen) return null;
  return phoneOnly ? (
    renderNotificationBottomSheet()
  ) : (
    <TransparentOverlay>
      <div style={{ padding: '72px 16px' }}>
        <SingleColumnLayout>
          <Card
            ref={notificationRef}
            css={{ boxShadow: '$2' }}
            changeStyle={{ borderRadius: '10px' }}
          >
            <Container
              id="notification-popup"
              onScroll={(e) => {
                loadMore(e);
              }}
            >
              <Header>
                <HeaderTitle>Notifications</HeaderTitle>
                <Subtitle
                  onClick={() => {
                    if (enableReadAll) resetUnreadCount();
                  }}
                >
                  {'Mark all as read'}
                </Subtitle>
              </Header>
              <Divider />
              {Array.isArray(data) && data.length > 0 ? (
                data.map((item, index) => {
                  return (
                    <NotificationListItem
                      hideRedDot={!enableReadAll}
                      item={item}
                      key={index}
                      updateNotification={readNotification}
                    />
                  );
                })
              ) : (
                <CenterContainer>
                  <Subtitle>{'No notifications available'}</Subtitle>
                </CenterContainer>
              )}
              {loading && <LoadingState />}
            </Container>
          </Card>
        </SingleColumnLayout>
      </div>
    </TransparentOverlay>
  );
};

export default NotificationComponent;
