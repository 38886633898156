import styled from '@/styles';

export const TextInputComponent = styled('input', {
  $$bgColor: 'white',
  $$borderColor: '$colors$neutralLight',
  $$textColor: '$colors$textDefault',
  $$placeholderTextColor: '$colors$textPlaceholder',

  $$hoverBgColor: 'white',
  $$hoverBorderColor: '$colors$themeDefault',

  $$focusBorderColor: '$colors$themeDefault',
  $$focusRingColor: '$colors$themeA24',

  $$disabledBgColor: '$colors$neutralLightest',
  $$disabledBorderColor: '$colors$neutralLight',
  $$disabledTextColor: '$colors$textLight',
  $$disabledPlaceholderTextColor: '$colors$textPlaceholder',

  $$errorTextColor: '$colors$error',

  width: '100%',

  backgroundColor: '$$bgColor',
  border: '1px',
  borderRadius: '$lg',
  color: '$$textColor',
  outline: 'none',

  fontFamily: '$system',
  fontSize: '$md',
  lineHeight: '28px',

  transition: 'box-shadow 200ms ease-in-out',
  '&::-webkit-input-placeholder': {
    color: '$$placeholderTextColor',
  },
  variants: {
    error: {
      true: {
        color: '$colors$dangerDarker',
      },
    },
  },
});

export const Container = styled('div', {
  $$borderColor: '$colors$neutralLight',

  border: '1px solid $$borderColor',
  borderRadius: '$lg',
  padding: '4px 12px',
});

export const InnerContainer = styled('div', {
  padding: '4px 12px 12px 12px',
});

export const EmailContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '5px',
  margin: '5px 0px',
});

export const EmailPill = styled('span', {
  background: '#ECF4E9',
  padding: '7px 10px',
  borderRadius: '15px',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  color: '#262626',
  justifyContent: 'space-between',
  maxWidth: '100%',
  flexShrink: 1,
  overflow: 'hidden',
});

export const EmailText = styled('p', {
  color: '#262626',
  fontSize: '12px',
  flexGrow: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export const RemoveButton = styled('div', {
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  fontSize: '12px',
  color: '#7A7A7A',
  flexShrink: 0,
});
