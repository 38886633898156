import { createSvgIcon } from '@mui/material';

const ContactIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fontSize: '1.25rem' }}
  >
    <path
      d="M1 8.5H2.25M4.75 8.5H2.25M1 11.5H2.25M4.75 11.5H2.25M1 14.5H2.25M4.75 14.5H2.25M2.25 8.5V6.25C2.25 5.65326 2.48705 5.08097 2.90901 4.65901C3.33097 4.23705 3.90326 4 4.5 4H19.5C20.0967 4 20.669 4.23705 21.091 4.65901C21.5129 5.08097 21.75 5.65326 21.75 6.25V18.75C21.75 19.3467 21.5129 19.919 21.091 20.341C20.669 20.7629 20.0967 21 19.5 21H4.5C3.90326 21 3.33097 20.7629 2.90901 20.341C2.48705 19.919 2.25 19.3467 2.25 18.75V14.5M2.25 8.5V11.5M2.25 11.5V14.5M14.5 9.875C14.5 10.1212 14.4515 10.365 14.3573 10.5925C14.263 10.82 14.1249 11.0267 13.9508 11.2008C13.7767 11.3749 13.57 11.513 13.3425 11.6073C13.115 11.7015 12.8712 11.75 12.625 11.75C12.3788 11.75 12.135 11.7015 11.9075 11.6073C11.68 11.513 11.4733 11.3749 11.2992 11.2008C11.1251 11.0267 10.987 10.82 10.8927 10.5925C10.7985 10.365 10.75 10.1212 10.75 9.875C10.75 9.37772 10.9475 8.90081 11.2992 8.54918C11.6508 8.19754 12.1277 8 12.625 8C13.1223 8 13.5992 8.19754 13.9508 8.54918C14.3025 8.90081 14.5 9.37772 14.5 9.875ZM15.794 16.211C14.8183 16.7307 13.7295 17.0017 12.624 17C11.5192 17.0014 10.4311 16.7304 9.456 16.211C9.69429 15.5622 10.1259 15.0023 10.6927 14.6067C11.2594 14.2111 11.9339 13.999 12.625 13.999C13.3161 13.999 13.9906 14.2111 14.5573 14.6067C15.1241 15.0023 15.5557 15.5622 15.794 16.211Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'Contact',
);

export default ContactIcon;
