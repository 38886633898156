import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
  Font,
} from '@react-pdf/renderer';
import { format, parseISO } from 'date-fns';
import PoppinsBold from 'src/routes/Agency/Job/fonts/Poppins-Bold.ttf';
import PoppinsRegular from 'src/routes/Agency/Job/fonts/Poppins-Regular.ttf';

import { AccountRec, Arrow, Mail, Phone, Pin, GravyLogo } from './Icons';
import InvoiceTable from './InvoiceTable';

import useAuth from '@/hooks/useAuth';
import {
  CurrentAgencyFragment,
  GetInvoiceDetailsRevampQuery,
} from '@/types/graphql';
import { parseTimestamp } from '@/util/date';
import { centsToCurrency } from '@/util/number';
import { isNullOrEmpty } from '@/util/constants';
import { getInvoiceIdentifier } from '../util';

Font.register({
  family: 'Poppins',
  fonts: [
    {
      src: PoppinsRegular,
    },
    {
      src: PoppinsBold,
      fontWeight: 'bold',
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 85,
    paddingHorizontal: 20,
  },
  stackRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  textStack: {
    display: 'flex',
    flexDirection: 'column',
  },
  stackCol: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '10px',
    marginRight: '12px',
    borderRight: '1px solid #D3D3D3',
  },
  logo: {
    width: 170,
    height: 36,
    textAlign: 'right',
    marginTop: '-6px',
  },
  icons: {
    width: '11px',
    height: '11px',
  },
  textLight: {
    fontFamily: 'Poppins',
    fontSize: 8,
    fontWeight: 400,
    color: '#7A7A7A',
  },
  textBold: {
    fontFamily: 'Poppins',
    fontSize: 10,
    fontWeight: 600,
  },
  text: {
    fontFamily: 'Poppins',
    fontSize: 8,
    color: '#262626',
  },
  greenText: {
    fontFamily: 'Poppins',
    fontSize: 8,
    color: '#45A735',
  },
  textSmall: {
    fontFamily: 'Poppins',
    fontSize: 7,
    color: '#262626',
  },
  textLarge: {
    fontFamily: 'Poppins',
    fontSize: 12,
    color: '#262626',
  },
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: 'flex',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    width: 'auto',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomColor: '#D3D3D3',
    borderBottomWidth: 1,
    minHeight: '18px',
    fontFamily: 'Poppins',
    color: '#262626',
  },
  tableColHeader: {
    width: '14%',
    fontSize: 7,
    fontWeight: 600,
    paddingRight: 7,
  },
  tableCol: {
    width: '14%',
    paddingRight: 7,
    paddingVertical: 5,
    alignSelf: 'center',
  },
  tableCell: {
    fontSize: 7,
    textAlign: 'left',
  },
  footer: {
    position: 'absolute',
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  footerSignature: {
    paddingVertical: 30,
    paddingHorizontal: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
});

// Prop types
type Props = {
  invoice: GetInvoiceDetailsRevampQuery['listStripeInvoiceDetail']['invoice'];
  invoiceItems: GetInvoiceDetailsRevampQuery['listStripeInvoiceDetail']['invoiceItems'];
  paymentMethod?: string;
  currentAgency: CurrentAgencyFragment | null;
  refiningInvoiceNumber: boolean;
};

const InvoiceDetailsPdf = ({
  invoice,
  invoiceItems,
  currentAgency,
  refiningInvoiceNumber = false,
}: Props) => {
  const dueDate = parseTimestamp(invoice.dueDate!);
  const invoiceAmountDue = centsToCurrency(Number(invoice.amountDue));
  // const { currentAdminIsCustomerAdmin } = useAuth();
  const agencyAddress = currentAgency?.address;
  const account = invoice.account;
  const contact = account.defaultContact?.user;
  const payUrl = `${window.location.origin}/pay-invoice/${invoice.invoiceUid}`;
  const accLink = true
    ? `${window.location.origin}/my-company/accounts/${account.id}`
    : `${window.location.origin}/clients/${invoice?.customer?.id}/accounts/${account.id}`;

  return (
    <Document>
      <Page wrap size="A4" style={styles.page}>
        <View style={styles.pageHeader}>
          <Image source={GravyLogo} style={styles.logo} />
          <View style={styles.stackRow}>
            <View style={styles.stackCol}>
              <Text style={styles.textLight}>{'Due Date: '}</Text>
              <Text style={styles.textBold}>
                {format(parseISO(dueDate), 'LLL dd, yyyy')}
              </Text>
            </View>
            <View
              style={{
                ...styles.stackCol,
                borderRight: undefined,
                marginRight: '0px',
              }}
            >
              <Text style={styles.textLight}>{'Amount Due: '}</Text>
              <Text style={styles.textBold}> ${invoiceAmountDue}</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            marginTop: 4,
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Link
            src={payUrl}
            style={{
              ...styles.textBold,
              color: '#4785FF',
              textDecoration: 'underline',
            }}
          >
            Pay Online
          </Link>
          <Image src={Arrow} style={{ width: '16px', height: '16px' }} />
        </View>
        <View style={{ marginTop: 4, flexDirection: 'row' }}>
          <Text style={styles.textLight}>Date: </Text>
          <Text style={styles.text}>
            {format(parseISO(invoice.createdDatetime), 'LLLL dd, yyyy')}
          </Text>
        </View>
        <View style={{ marginBottom: 14, marginTop: 5, flexDirection: 'row' }}>
          <Text style={{ ...styles.textLarge, fontWeight: 600 }}>Invoice </Text>
          <Text style={styles.textLarge}>
            {getInvoiceIdentifier(refiningInvoiceNumber, invoice)}
          </Text>
        </View>

        <View style={styles.stackRow}>
          <View style={{ ...styles.stackCol, width: '32%' }}>
            <View style={styles.stackRow}>
              <Text style={{ ...styles.textLight, fontWeight: 600 }}>
                {'From: '}
              </Text>
              <Text
                style={{ ...styles.text, fontWeight: 600, marginRight: '6px' }}
              >
                {currentAgency?.name}
              </Text>
            </View>
            <View style={{ ...styles.stackRow, marginVertical: '5px' }}>
              <View style={styles.icons}>
                <Image src={Pin} />
              </View>
              <View style={{ ...styles.textStack, paddingLeft: '4px' }}>
                <Text style={styles.text}>
                  {`${agencyAddress?.addressLine1}`}
                </Text>
                {!isNullOrEmpty(agencyAddress?.addressLine2) && (
                  <Text style={styles.text}>{agencyAddress?.addressLine2}</Text>
                )}
                <Text
                  style={styles.text}
                >{`${agencyAddress?.city}, ${agencyAddress?.state} ${agencyAddress?.zip}`}</Text>
              </View>
            </View>
            <View style={{ ...styles.stackRow, marginBottom: '5px' }}>
              <View style={styles.icons}>
                <Image src={Phone} />
              </View>
              <Text style={{ ...styles.text, paddingLeft: '4px' }}>
                {currentAgency?.phoneNumber}
              </Text>
            </View>
            <View style={{ ...styles.stackRow, marginBottom: '5px' }}>
              <View style={styles.icons}>
                <Image src={Mail} />
              </View>
              <Text style={{ ...styles.greenText, paddingLeft: '4px' }}>
                {currentAgency?.email}
              </Text>
            </View>
          </View>

          <View style={{ ...styles.stackCol, width: '35%' }}>
            <View style={styles.stackRow}>
              <Text style={{ ...styles.textLight, fontWeight: 600 }}>
                {'Customer: '}
              </Text>
              <Text
                style={{ ...styles.text, fontWeight: 600, marginRight: '6px' }}
              >
                {` ${invoice?.customer?.name || ''}`}
              </Text>
            </View>
            <View style={{ ...styles.stackRow, marginVertical: '5px' }}>
              <View style={styles.icons}>
                <Image src={Pin} />
              </View>
              <View style={{ ...styles.textStack, paddingLeft: '4px' }}>
                <Text style={styles.text}>{account.addressLine1}</Text>
                {!isNullOrEmpty(account.addressLine2) && (
                  <Text style={styles.text}>{account.addressLine2}</Text>
                )}
                <Text
                  style={styles.text}
                >{`${account.city}, ${account.state} ${account.zip}`}</Text>
              </View>
            </View>
            <View style={{ ...styles.stackRow, marginBottom: '5px' }}>
              <View style={styles.icons}>
                <Image src={Mail} />
              </View>
              <Text style={{ ...styles.text, paddingLeft: '4px' }}>
                {contact.email}
              </Text>
            </View>
            <View style={{ ...styles.stackRow, marginBottom: '5px' }}>
              <View style={styles.icons}>
                <Image src={AccountRec} />
              </View>
              <Link
                src={accLink}
                style={{
                  ...styles.greenText,
                  paddingLeft: '4px',
                  textDecoration: 'none',
                }}
              >
                {account.name}
              </Link>
            </View>
          </View>
          <View
            style={{
              ...styles.stackCol,
              width: '33%',
              borderRight: undefined,
              marginRight: 0,
            }}
          >
            <Text
              style={{ ...styles.text, fontWeight: 600, marginBottom: '5px' }}
            >
              {`Description`}
            </Text>
            <Text style={styles.text}>{invoice.description}</Text>
          </View>
        </View>
        <View style={{ marginVertical: '12px' }}>
          <Text style={{ ...styles.textLarge, fontWeight: 600 }}>
            Invoice Items
          </Text>
        </View>
        <InvoiceTable
          amountDue={invoiceAmountDue}
          invoiceItems={invoiceItems}
          styles={styles}
        />
        <View fixed style={styles.footer}>
          <View style={styles.footerSignature}>
            <Text
              style={{
                ...styles.textSmall,
              }}
            >
              {`${invoice.number} · $${invoiceAmountDue} USD due ${format(
                parseISO(dueDate),
                'LLL dd, yyyy',
              )}`}
            </Text>
            <Text
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
              style={{
                ...styles.textSmall,
              }}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoiceDetailsPdf;
