import { useEffect } from 'react';

/**
 * Hook to disable body scrolling
 * @param shouldDisable Optional parameter to conditionally disable scrolling (defaults to true for backward compatibility)
 */
const useDisableBodyScroll = (shouldDisable: boolean = true) => {
  useEffect(() => {
    if (shouldDisable) {
      // Disable scrolling on body
      document.body.classList.add('noscroll');

      // Find main element and disable scrolling there too
      const mainElement = document.querySelector('main');
      if (mainElement) {
        mainElement.classList.add('noscroll');
      }

      return () => {
        document.body.classList.remove('noscroll');
        if (mainElement) {
          mainElement.classList.remove('noscroll');
        }
      };
    }

    // If not disabling, make sure we remove the class in case it was previously added
    document.body.classList.remove('noscroll');
    const mainElement = document.querySelector('main');
    if (mainElement) {
      mainElement.classList.remove('noscroll');
    }

    return undefined;
  }, [shouldDisable]);
};

export default useDisableBodyScroll;
