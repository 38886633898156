import Config from '@/config';
import { GAEvent } from '@/constants/gaevents';
import Text from '@/elements/Text';
import styled from '@/styles';
import useAnalytics from '@/util/analytics';
import { DevicePhoneMobileIcon } from '@heroicons/react/24/outline';
import HeroIcon from '@/components/HeroIcon';

const Button = styled('div', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#EAF1FF',
  height: '32px',
  borderRadius: '8px',
  padding: '0 12px',
  gap: '6px',
  cursor: 'pointer',
  whiteSpace: 'nowrap',

  '&:hover': {
    backgroundColor: '#EAF1FF',
  },

  '@phoneOnly': {
    margin: '0 8px',
  },
});

const AppButtonV2 = () => {
  const { logEvent } = useAnalytics();

  const handleClick = () => {
    var isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
    logEvent(GAEvent.OpenInAppClickedFromPhone);
    if (isIOSDevice) {
      window.open(Config.APP_STORE_REDIRECT_URL, '_blank');
    } else {
      window.open(Config.PLAY_STORE_REDIRECT_URL, '_blank');
    }
  };

  return (
    <Button onClick={handleClick}>
      <HeroIcon icon={DevicePhoneMobileIcon} size="xs" />
      <Text size="sm" weight="bold" color="black">
        Open in app
      </Text>
    </Button>
  );
};

export default AppButtonV2;
