import { useReactiveVar } from '@apollo/client';
import { useCallback, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { SubmitErrorAlert } from '@/components/Alerts';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import { GAEvent } from '@/constants/gaevents';
import { V3Endpoints } from '@/constants/urls';
import { useCreateAccountMutation } from '@/graphql';
import { AccountForm } from '@/routes/Agency/common/components';
import {
  AccountFormValues,
  AdditionalEmailsRequest,
  DeliveryTypeEnum,
  NotificationTypeEnum,
  SubjectTypeEnum,
} from '@/routes/Agency/common/components/AccountForm';
import { Role } from '@/routes/PrivateRoute';
import { Maybe } from '@/types';
import { AccountRateTypeEnum, GetCustomerQuery } from '@/types/graphql';
import useAnalytics from '@/util/analytics';
import { currentAdminVar } from '@/util/apollo/cache';
import axiosClient from '@/util/axios/axiosClient';

type Props = {
  customer: GetCustomerQuery['customer'];
  hideModal: () => void;
  rateType: string;
};

const CreateAccountModal = ({ customer, hideModal, rateType }: Props) => {
  const [submitError, setSubmitError] = useState<Maybe<Error>>(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [_emailList, setEmailList] = useState<string[]>([]);

  const upsertAdditionalEmail = async (id, emailList) => {
    try {
      const response = await axiosClient.post<AdditionalEmailsRequest>(
        V3Endpoints.UPSERT_ADDITIONAL_EMAILS,
        {
          subjectType: SubjectTypeEnum.ACCOUNT,
          subjectId: parseInt(id),
          notificationType: NotificationTypeEnum.INVOICE,
          deliveryType: DeliveryTypeEnum.EMAIL,
          updatedEmailList: emailList,
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error upserting additional email:', error);
    }
  };

  const { logEvent } = useAnalytics();
  const [createAccount, { loading }] = useCreateAccountMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(customer),
        fields: {
          accounts() {},
        },
      });
    },
    onCompleted: async ({ accountCreate }) => {
      const accountId = accountCreate.account.id;
      if (accountId && _emailList.length > 0) {
        logEvent(GAEvent.UpdatedAdditionalEmails, accountId);
        await upsertAdditionalEmail(accountId, _emailList);
      }
      if (customer.accounts.length === 0) {
        logEvent(GAEvent.BillingSetup, accountId);
      }
      navigate(`${pathname}/accounts/${accountId}`);
    },
    onError: setSubmitError,
  });

  const currentAdmin = useReactiveVar(currentAdminVar);
  const isTenantAdmin = currentAdmin?.role === Role.TENANT_ADMIN;
  const onSubmit: SubmitHandler<AccountFormValues> = useCallback(
    ({
      accountName,
      defaultContact,
      address,
      invoiceDuration,
      crossMonthInvoice,
      billingWeek,
      invoiceSweepRule,
      groupBy,
      markupPercent = '',
      emailList,
    }) => {
      if (emailList) {
        setEmailList(emailList);
      }
      const markupPercentageValue = isTenantAdmin
        ? Number(markupPercent?.replace('%', ''))
        : customer?.markupPercent;

      createAccount({
        // @ts-ignore
        variables: {
          customerId: customer.id,
          name: accountName,
          defaultContactId: defaultContact.id,
          ...address,
          addressLine2: address.addressLine2 ?? null,
          cutOff: invoiceDuration,
          monthlyCutOff: crossMonthInvoice,
          billingWeek,
          consolidateUnprocessedCharges: invoiceSweepRule,
          groupBy,
          ...(rateType === AccountRateTypeEnum.MARKUP && {
            markupPercent: markupPercentageValue,
          }),
          rateType: AccountRateTypeEnum[rateType],
        },
      });
    },
    [],
  );

  return (
    <Modal
      disableClickout
      size="sm"
      title={
        rateType === 'MARKUP'
          ? 'New Markup Billing Account'
          : 'New billing account'
      }
      wrapperBackground={true}
      onRequestClose={hideModal}
    >
      <Card.Section>
        {submitError && <SubmitErrorAlert description={submitError.message} />}
        <AccountForm
          {...{ customer, isLoading: loading, onSubmit, rateType }}
          newAccount={true}
          defaultEmail={null}
        />
      </Card.Section>
    </Modal>
  );
};

export default CreateAccountModal;
