import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import PaymentSection from '../../Account/PaymentSection';
import ErrorSkeleton from '../Skeletons/ErrorSkeleton';

import InvoicePayCard from './InvoicePayCard';
import InvoicePaySkeleton from './InvoicePaySkeleton';

import Page from '@/components/Page';
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout';
import { HUMAN_DATE_FORMAT } from '@/constants/datetime';
import { useGetInvoiceDetailsRevampQuery } from '@/graphql';
import { StripeInvoiceStatusEnum } from '@/types/graphql';
import { parseTimestamp } from '@/util/date';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { getInvoiceIdentifier } from '../util';

const InvoicePay = () => {
  const { invoiceId } = useParams();
  const { loading, error, data, refetch } = useGetInvoiceDetailsRevampQuery({
    variables: { invoiceId },
    fetchPolicy: 'no-cache',
  });
  const [paymentMethod, setPaymentMethod] = useState<string>('');

  if (loading) {
    return <InvoicePaySkeleton />;
  }

  if (error || !data) {
    return <ErrorSkeleton />;
  }

  const { invoice, invoiceItems } = data.listStripeInvoiceDetail;
  const parsedDueDate = parseTimestamp(invoice.dueDate ?? '');
  const formattedDueDate = format(parseISO(parsedDueDate), HUMAN_DATE_FORMAT);
  const showPaymentSection = invoice.status === StripeInvoiceStatusEnum.OPEN;
  const refiningInvoiceNumber = useFeatureValue(
    FEATURE_TOGGLE.RefiningInvoiceNumber,
    false,
  );

  return (
    <Page
      subtitle={`Due ${formattedDueDate}`}
      title={`Invoice #${getInvoiceIdentifier(refiningInvoiceNumber, invoice)}`}
    >
      <PrimarySecondaryLayout>
        <PrimarySecondaryLayout.Primary>
          <InvoicePayCard
            invoice={invoice}
            invoiceItems={invoiceItems}
            paymentMethod={paymentMethod}
            refetch={refetch}
            showPayButton={showPaymentSection}
          />
        </PrimarySecondaryLayout.Primary>
        {showPaymentSection && (
          <PrimarySecondaryLayout.Secondary>
            <PaymentSection
              account={invoice.account}
              setPaymentMethod={setPaymentMethod}
            />
          </PrimarySecondaryLayout.Secondary>
        )}
      </PrimarySecondaryLayout>
    </Page>
  );
};

export default InvoicePay;
