import { useReactiveVar } from '@apollo/client';
import {
  BriefcaseIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ShoppingCartIcon,
  Squares2X2Icon,
  UserIcon,
  UsersIcon,
  ChatBubbleOvalLeftEllipsisIcon,
} from '@heroicons/react/24/outline';
import { useMemo } from 'react';

import PageSkeleton from './PageSkeleton';
import { CollapseButton, TooltipWrapper, MenuItemText } from './styles';

import {
  ContactIcon,
  InvoiceIcon,
  PayrollRunsIcon,
  ReportsIcon,
} from '@/assets/icons';
import InvoiceGeneratorIconV2 from '@/assets/icons/InvoiceGeneratorIconV2';
import JobMonitorIconV2 from '@/assets/icons/JobMonitorIconV2';
import NotificationHubIconV2 from '@/assets/icons/NotificationHubIconV2';
import HeroIcon from '@/components/HeroIcon';
import NavigationMenu from '@/components/NavigationMenu';
import Stack from '@/components/Stack';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { useAppDispatch } from '@/hooks/store';
import ROUTES from '@/routes/routes';
import { setRefetchDashboardData } from '@/store/slices/layoutSlice';
import { currentAdminVar, currentAgencyVar } from '@/util/apollo/cache';
import { useFeatureValue } from '@growthbook/growthbook-react';

interface SidebarProps {
  isCollapsed?: boolean;
  onToggleCollapse?: () => void;
}

const SidebarV2 = ({ isCollapsed, onToggleCollapse }: SidebarProps) => {
  const currentAgency = useReactiveVar(currentAgencyVar);
  const currentAdmin = useReactiveVar(currentAdminVar);
  const ReleaseNotesAdmin = useFeatureValue(
    FEATURE_TOGGLE.ReleaseNotesAdmin,
    false,
  );

  const dispatch = useAppDispatch();
  const dashboardClick = () => {
    if (window.location.pathname !== ROUTES.basePath) {
      dispatch(setRefetchDashboardData(true));
    }
  };

  const renderIcon = (IconComponent: any, a11yLabel: string) => {
    const icon = <HeroIcon icon={IconComponent} />;
    return () =>
      isCollapsed ? (
        <TooltipWrapper data-tooltip={a11yLabel}>{icon}</TooltipWrapper>
      ) : (
        icon
      );
  };

  const renderCustomIcon = (Icon: any, label: string) => {
    return () =>
      isCollapsed ? (
        <TooltipWrapper data-tooltip={label}>
          <Icon />
        </TooltipWrapper>
      ) : (
        <Icon />
      );
  };

  const adminNavigation = useMemo(
    () => [
      {
        id: 'menu-general',
        items: [
          {
            CustomIcon: renderIcon(Squares2X2Icon, 'Dashboard'),
            a11yLabel: 'Dashboard',
            to: ROUTES.basePath,
            end: true,
            id: 'dashboard-link',
            onClick: dashboardClick,
          },
          {
            CustomIcon: renderIcon(ChatBubbleOvalLeftEllipsisIcon, 'Chat'),
            a11yLabel: 'Chat',
            to: ROUTES.inappchat,
            end: true,
            id: 'inappchat-link',
          },
          {
            CustomIcon: renderIcon(ChatBubbleOvalLeftEllipsisIcon, 'Chat'),
            a11yLabel: 'Chat',
            to: ROUTES.chat,
            end: true,
            id: 'chat-link',
          },
        ],
      },
      {
        id: 'menu-gravywork-team',
        title: 'GravyWork Team',
        withBorder: true,
        items: [
          {
            CustomIcon: renderIcon(BriefcaseIcon, 'Clients'),
            a11yLabel: 'Clients',
            to: ROUTES.clients,
            id: 'clients-link',
          },
          {
            CustomIcon: renderIcon(ContactIcon, 'Contacts'),
            a11yLabel: 'Contacts',
            to: ROUTES.contacts,
            id: 'contacts-link',
          },
          {
            CustomIcon: renderIcon(UsersIcon, 'GravyWorkers'),
            a11yLabel: 'GravyWorkers',
            to: ROUTES.workers,
            id: 'workers-link',
          },
        ],
      },
      {
        id: 'menu-admin',
        title: 'Admin',
        withBorder: true,
        items: [
          {
            CustomIcon: renderIcon(ShoppingCartIcon, 'Orders'),
            a11yLabel: 'Orders',
            to: ROUTES.orders,
            id: 'orders-link',
          },
          {
            CustomIcon: renderIcon(InvoiceIcon, 'Invoices'),
            a11yLabel: 'Invoices',
            to: ROUTES.invoices,
            id: 'invoices-link',
          },
          {
            CustomIcon: renderCustomIcon(
              InvoiceGeneratorIconV2,
              'Invoice Generator',
            ),
            a11yLabel: 'Invoice Generator',
            to: ROUTES.invoiceGenerator,
            id: 'inv-generator-link',
          },
          {
            CustomIcon: renderCustomIcon(PayrollRunsIcon, 'Payroll Runs'),
            a11yLabel: 'Payroll Runs',
            to: ROUTES.payrolls,
            id: 'payrolls-link',
          },
          {
            CustomIcon: renderCustomIcon(ReportsIcon, 'Reports'),
            a11yLabel: 'Reports',
            to: ROUTES.reports,
            id: 'reports-link',
          },
          {
            CustomIcon: renderCustomIcon(JobMonitorIconV2, 'Job Monitor'),
            a11yLabel: 'Job Monitor',
            to: ROUTES.jobmonitor,
            id: 'jobmonitor-link',
          },
          ...(ReleaseNotesAdmin
            ? [
                {
                  CustomIcon: renderCustomIcon(
                    NotificationHubIconV2,
                    'Notification Hub',
                  ),
                  a11yLabel: 'Notification Hub',
                  to: ROUTES.notificationHub,
                  id: 'notificationhub-link',
                },
              ]
            : []),
        ],
      },
      {
        id: 'menu-my-information',
        title: 'My Information',
        items: [
          {
            CustomIcon: renderIcon(UsersIcon, 'My Team'),
            a11yLabel: 'My Team',
            to: ROUTES.myTeam,
            id: 'myteam-link',
          },
          {
            CustomIcon: renderIcon(BriefcaseIcon, 'My Company'),
            a11yLabel: 'My Company',
            to: ROUTES.myCompany,
            id: 'mycompany-link',
          },
          {
            CustomIcon: renderIcon(UserIcon, 'My Profile'),
            a11yLabel: 'My Profile',
            to: ROUTES.myProfile,
            id: 'profile-link',
          },
        ],
      },
    ],
    [currentAdmin, ReleaseNotesAdmin, isCollapsed],
  );

  const renderMenuText = (text: string) => {
    return <MenuItemText isCollapsed={isCollapsed}>{text}</MenuItemText>;
  };

  return (
    <Stack vertical css={{ position: 'relative' }}>
      <CollapseButton onClick={onToggleCollapse} isCollapsed={isCollapsed}>
        <HeroIcon
          icon={isCollapsed ? ChevronRightIcon : ChevronLeftIcon}
          size="xs"
        />
      </CollapseButton>

      {currentAdmin ? (
        <NavigationMenu
          groups={adminNavigation}
          isCollapsed={isCollapsed}
          renderText={renderMenuText}
        />
      ) : (
        <PageSkeleton />
      )}
    </Stack>
  );
};

export default SidebarV2;
