import { NavLink } from 'react-router-dom';
import { useFeatureValue } from '@growthbook/growthbook-react';

import PendingDotIcon from '@/assets/icons/PendingDot';
import Icon from '@/elements/Icon';
import Text from '@/elements/Text';
import { useAppSelector } from '@/hooks/store';
import PermissionComponent from '@/routes/PermissionComponent';
import styled from '@/styles';
import { LinkAction } from '@/types';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';

const Wrapper = styled('nav', {
  display: 'flex',
  flexDirection: 'column',
  padding: 16,
  width: '100%',
  transition: 'width 0.2s ease',

  variants: {
    isCollapsed: {
      true: {
        width: '68px',
        padding: '16px 8px',
      },
    },
  },
});

const Group = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '20px',
  position: 'relative',

  '&:first-child': {
    marginTop: 0,
  },

  variants: {
    withBorder: {
      true: {},
    },
    isCollapsed: {
      true: {},
      false: {},
    },
  },

  compoundVariants: [
    {
      withBorder: true,
      isCollapsed: true,
      css: {
        paddingTop: '20px',

        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: '10%',
          width: '80%',
          height: '2px',
          backgroundColor: '$neutralA8',
          margin: '0 auto',
        },
      },
    },
  ],
});

const Item = styled(NavLink, {
  alignItems: 'center',
  display: 'flex',
  gap: '20px',
  padding: '12px 20px',
  backgroundColor: 'transparent',
  borderRadius: '$xxl',
  color: '$textLight',
  fontWeight: '$medium',
  textDecoration: 'none',
  transition: 'all 0.2s ease',
  margin: '6px',

  '& + &': {
    marginTop: '2px',
  },

  '&:hover': {
    backgroundColor: '$neutralA4',
  },

  '&.active': {
    backgroundColor: '$themeA12',
    color: '$themeDefault',
    fontWeight: '$medium',

    '&:hover': {
      backgroundColor: '$themeA16',
    },
  },

  focusPseudoElement: {
    element: 'after',
  },

  '& > span': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  variants: {
    isCollapsed: {
      true: {
        padding: '10px 8px',
        justifyContent: 'center',
        gap: '8px',

        '& > span': {
          display: 'none',
        },
      },
    },
    status: {
      active: {
        backgroundColor: '$themeA12',
        color: '$themeDarkest',
        fontWeight: '$medium',

        '&:hover': {
          backgroundColor: '$themeA16',
        },
      },
    },
  },
});

const StyledPendingDot = styled('div', {
  position: 'absolute',
  top: '-1px',

  variants: {
    isCollapsed: {
      true: {
        left: '33px',
      },
      false: {
        left: '35px',
      },
    },
  },
});

type LinkAction = {
  id: string;
  to: string;
  label?: string;
  a11yLabel?: string;
  icon?: string;
  CustomIcon?: () => JSX.Element;
  end?: boolean;
  hide?: boolean;
  onClick?: () => void;
};

type NavigationGroup = {
  items: LinkAction[];
  title?: string;
  id?: string;
  withBorder?: boolean;
};

type Props = {
  groups: NavigationGroup[];
  isCollapsed?: boolean;
  renderText?: (text: string) => JSX.Element;
};

const NavigationMenu = ({ groups, isCollapsed, renderText }: Props) => {
  const unreadChats = useAppSelector((state) => state.pubnub.unreadChats);
  const pendingCustomers = useAppSelector(
    (state) => state.layout.pendingCustomers || 0,
  );

  const useNavigationImprovements = useFeatureValue(
    FEATURE_TOGGLE.NavigationImprovements,
    false,
  );

  return (
    <Wrapper isCollapsed={isCollapsed}>
      {groups.map((group, groupIndex) => (
        <PermissionComponent key={`group-${group.id}`} id={`group-${group.id}`}>
          <Group
            key={`group-${groupIndex}`}
            withBorder={group.withBorder}
            isCollapsed={isCollapsed}
          >
            {group.title && !isCollapsed && (
              <div style={{ margin: '0 10px 10px' }}>
                <Text size="sm" weight="semibold">
                  {group.title}
                </Text>
              </div>
            )}
            {group.items.map((item, itemIndex) => {
              const { CustomIcon, hide } = item;
              if (hide) return <></>;
              return (
                <PermissionComponent
                  key={`nav-${item.id}`}
                  id={`nav-${item.id}`}
                >
                  <Item
                    key={`item-${groupIndex}-${itemIndex}`}
                    end={item.end}
                    id={`nav-${item.id}`}
                    to={item.to}
                    onClick={item?.onClick}
                    isCollapsed={isCollapsed}
                  >
                    {item.icon && (
                      <Text color="inherit" size="lg">
                        <Icon fixedWidth icon={item.icon} />
                      </Text>
                    )}
                    {CustomIcon && <CustomIcon />}

                    {!isCollapsed && (
                      <>
                        {renderText ? (
                          renderText(item.label || item.a11yLabel || '')
                        ) : (
                          <Text
                            color="inherit"
                            size="md"
                            weight="inherit"
                            css={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {item.label || item.a11yLabel}
                          </Text>
                        )}
                      </>
                    )}
                    {useNavigationImprovements ? (
                      <>
                        {item.a11yLabel === 'Clients' &&
                          pendingCustomers > 0 && (
                            <StyledPendingDot isCollapsed={isCollapsed}>
                              <PendingDotIcon />
                            </StyledPendingDot>
                          )}
                        {item.a11yLabel === 'Chat' && unreadChats && (
                          <StyledPendingDot isCollapsed={isCollapsed}>
                            <PendingDotIcon />
                          </StyledPendingDot>
                        )}
                      </>
                    ) : (
                      <>
                        {item.a11yLabel === 'Clients' &&
                          pendingCustomers > 0 && <PendingDotIcon />}
                        {item.a11yLabel === 'Chat' && unreadChats && (
                          <PendingDotIcon />
                        )}
                      </>
                    )}
                  </Item>
                </PermissionComponent>
              );
            })}
          </Group>
        </PermissionComponent>
      ))}
    </Wrapper>
  );
};

NavigationMenu.Wrapper = Wrapper;
Wrapper.displayName = 'stitches(NavigationMenu.Wrapper)';

NavigationMenu.Group = Group;
Group.displayName = 'stitches(NavigationMenu.Group)';

NavigationMenu.Item = Item;
Item.displayName = 'stitches(NavigationMenu.Item)';

export default NavigationMenu;
