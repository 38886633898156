import Card from '@/components/Card';
import TextStack from '@/components/TextStack';
import { Small } from '@/components/Typography';
import type { GetAccountQuery } from '@/types/graphql';

interface AdditionalEmailSectionProps {
  additionalEmails: GetAccountQuery['account']['additionalEmails'];
}

const TextStyle: React.CSSProperties = {
  width: '98%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: '#5E6C84',
};

const AdditionalEmailSection = ({
  additionalEmails,
}: AdditionalEmailSectionProps) => {
  const hasAdditionalEmails = additionalEmails && additionalEmails.length > 0;

  return (
    <Card.Section title="Additional Billing Emails">
      <TextStack>
        {hasAdditionalEmails ? (
          additionalEmails.map((email, index) => (
            <span style={TextStyle} key={index}>
              <Small>{email}</Small>
            </span>
          ))
        ) : (
          <Small>No additional billing emails</Small>
        )}
      </TextStack>
    </Card.Section>
  );
};

export default AdditionalEmailSection;
