import { Fragment, ReactNode } from 'react';

import Item from './Item';

import Dropdown, { Props as DropdownProps } from '@/components/Dropdown';
import styled from '@/styles';
import { GenericAction } from '@/types';
import { noOp } from '@/util/actions';
import Stack from '@/components/Stack';
import Text from '@/elements/Text';
import Avatar from '@/components/Avatar';

const Wrapper = styled('ul', {
  display: 'block',
  padding: '0',
  margin: 0,
  listStyle: 'none',
  minWidth: '240px',
  backgroundColor: 'white',
  borderRadius: '8px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
});

Wrapper.displayName = 'stitches(DropdownMenu.Wrapper)';

const Separator = styled('li', {
  display: 'block',
  height: '1px',
  margin: '6px 0',

  borderBottom: '1px solid $colors$neutralLightest',
});

Separator.displayName = 'stitches(DropdownMenu.Separator)';

const Header = styled('div', {
  padding: '16px',
  borderBottom: '1px solid #E5E7EB',
});

const Content = styled('div', {
  padding: '4px 0',
});

export type Props = Omit<DropdownProps, 'Content'> & {
  actions: GenericAction[][];
  disableCloseOnClick?: boolean;
  header?: ReactNode;
};

const DropdownMenu = ({
  actions,
  disableCloseOnClick = false,
  header,
  ...props
}: Props) => {
  return (
    <Dropdown
      {...props}
      Content={({ closeDropdown }) => (
        <Wrapper role="menu">
          {header && <Header>{header}</Header>}
          <Content>
            {actions.map((group, groupIndex) => (
              <Fragment key={`group-${groupIndex}`}>
                {group.map((action, itemIndex) => (
                  <Item
                    key={`item-${groupIndex}-${itemIndex}`}
                    action={action}
                    id={`item-${action.id || ''}`}
                    onAction={disableCloseOnClick ? noOp : closeDropdown}
                  />
                ))}
                {groupIndex < actions.length - 1 && (
                  <Separator aria-hidden role="separator" />
                )}
              </Fragment>
            ))}
          </Content>
        </Wrapper>
      )}
    />
  );
};

export default DropdownMenu;
