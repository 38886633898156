import { createSvgIcon } from '@mui/material';

const InvoiceGeneratorIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fontSize: '1.3rem' }}
  >
    <path
      d="M19.5 20.7496V3.75657C19.5 2.64857 18.693 1.69957 17.593 1.57157C13.8768 1.14021 10.1232 1.14021 6.407 1.57157C5.306 1.69957 4.5 2.64857 4.5 3.75657V20.7496L10 19.5L12 20.7496L15.75 19.2496L19.5 20.7496Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 10.7692L13.303 5L12.1667 9.23077H16.3333L11.0303 15L12.1667 10.7692H8Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'InvoiceGenerator',
);

export default InvoiceGeneratorIcon;
