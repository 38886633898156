import { Fragment, ReactNode, useEffect } from 'react';

import Item from './Item';

import Dropdown from '@/components/Dropdown';
import styled from '@/styles';
import { GenericAction } from '@/types';
import { noOp } from '@/util/actions';

const Wrapper = styled('ul', {
  display: 'block',
  padding: '0',
  margin: 0,
  listStyle: 'none',
  minWidth: '240px',
  backgroundColor: 'white',
  marginRight: '-6px',
  marginTop: '3px',
});

Wrapper.displayName = 'stitches(DropdownMenu.Wrapper)';

const Separator = styled('li', {
  display: 'block',
  height: '1px',
  margin: '6px 0',

  borderBottom: '1px solid $colors$neutralLightest',
});

Separator.displayName = 'stitches(DropdownMenu.Separator)';

const Header = styled('div', {
  padding: '18px 18px 2px 18px',
});

const Content = styled('div', {
  padding: '6px 6px 14px 6px',
});

export type Props = {
  actions: GenericAction[][];
  children: ReactNode;
  disableCloseOnClick?: boolean;
  header?: ReactNode;
  justify?: 'left' | 'right';
  margin?: string;
  size?: 'sm' | 'md';
  onOpenChange?: (isOpen: boolean) => void;
};

const DropdownMenuV2 = ({
  actions,
  children,
  disableCloseOnClick,
  header,
  justify = 'right',
  margin,
  size = 'md',
  onOpenChange,
}: Props) => {
  return (
    <Dropdown
      justify="right"
      Content={({ isOpen, closeDropdown }) => {
        useEffect(() => {
          onOpenChange?.(isOpen);
        }, [isOpen]);

        return (
          <Wrapper role="menu">
            {header && <Header>{header}</Header>}
            <Content>
              {actions.map((group, groupIndex) => (
                <Fragment key={`group-${groupIndex}`}>
                  {group.map((action, itemIndex) => (
                    <Item
                      key={`item-${groupIndex}-${itemIndex}`}
                      action={action}
                      id={`item-${action.id || ''}`}
                      onAction={disableCloseOnClick ? noOp : closeDropdown}
                    />
                  ))}
                </Fragment>
              ))}
            </Content>
          </Wrapper>
        );
      }}
    >
      {children}
    </Dropdown>
  );
};

export default DropdownMenuV2;
