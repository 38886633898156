import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Small } from '../Typography';
import {
  Container,
  EmailContainer,
  EmailPill,
  EmailText,
  RemoveButton,
  TextInputComponent,
} from './styles';
import { EMAIL_REGEX } from '@/constants/regex';

interface EmailPillInputProps {
  onEmailsChange: (emails: string[]) => void;
  defaultEmail?: string | null;
  existingEmails?: string[];
}

const EmailPillInput = forwardRef(
  (
    { onEmailsChange, defaultEmail, existingEmails = [] }: EmailPillInputProps,
    ref,
  ) => {
    const [inputValue, setInputValue] = useState('');
    const [emails, setEmails] = useState<string[]>(existingEmails);
    const [error, setError] = useState<string | null>(null);

    useImperativeHandle(ref, () => {
      return {
        clearInput: () => {
          setInputValue('');
        },
      };
    });

    const isValidEmail = (email: string) => EMAIL_REGEX.test(email);

    const handleKeyDown = () => {
      const email = inputValue.trim().replace(/,$/, '');
      if (isValidEmail(email)) {
        if ([...emails, defaultEmail].includes(email)) {
          setError('This email already exists');
          return;
        }
        if (
          emails.length === parseInt(process.env.MAX_ADDITIONAL_EMAILS || '10')
        ) {
          setError('You can only add up to 10 emails');
          return;
        }
        const newEmails = [...emails, email];
        setEmails(newEmails);
        onEmailsChange(newEmails);
        setError(null);

        setInputValue('');
      } else {
        setError('Invalid email address');
      }
    };

    const removeEmail = (index: number) => {
      const updatedEmails = emails.filter((_, i) => i !== index - 1);
      setEmails(updatedEmails);
      onEmailsChange(updatedEmails);
    };

    const newArray = defaultEmail ? [defaultEmail, ...emails] : emails;

    return (
      <div>
        <Container>
          <TextInputComponent
            placeholder="Enter email separated by comma"
            value={inputValue}
            onChange={(e) => {
              const text = e.target.value;
              if (text.slice(-1) === ',' || text.slice(-1) === '\n') {
                handleKeyDown();
              } else {
                setInputValue(text);
              }
            }}
            error={!!error}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleKeyDown();
              }
            }}
            ref={ref}
          />

          {newArray.length > 0 && (
            <EmailContainer>
              {newArray.map((email, index) => (
                <EmailPill key={index}>
                  <EmailText title={email}>{email}</EmailText>
                  {index > 0 && (
                    <RemoveButton onClick={() => removeEmail(index)}>
                      ✕
                    </RemoveButton>
                  )}
                </EmailPill>
              ))}
            </EmailContainer>
          )}
        </Container>
        {error && (
          <Small color="danger" role="alert">
            {error}
          </Small>
        )}
      </div>
    );
  },
);

export default EmailPillInput;
