import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { VariantProps, CSS } from '@stitches/react';
import { useCallback, useState } from 'react';

import TextStack from './TextStack';

import Icon from '@/elements/Icon';
import Shimmer from '@/elements/Shimmer';
import styled from '@/styles';
import Text from '@/elements/Text';

const Wrapper = styled('div', {
  position: 'relative',
});

const AvatarWrapper = styled(Shimmer, {
  alignItems: 'center',
  boxSize: '100%',
  display: 'flex',
  fontSize: '100%',
  justifyContent: 'center',
  overflow: 'hidden',

  variants: {
    size: {
      xs: {
        boxSize: 24,
      },
      sm: {
        boxSize: 36,
      },
      m: {
        boxSize: 42,
      },
      md: {
        boxSize: 48,
      },
      lg: {
        boxSize: 64,
      },
      lg2: {
        boxSize: 80,
      },
      xl: {
        boxSize: 128,
      },
      profilePic: {
        boxSize: '95%',
      },
    },
    rounded: {
      true: {
        borderRadius: '$round',
      },
      false: {
        borderRadius: '$lg',
      },
    },
    border: {
      true: {
        border: '2px solid #45A735',
      },
      false: {
        border: 'none',
      },
    },
    borderLg: {
      true: {
        border: '4px solid #45A735',
      },
      false: {
        border: 'none',
      },
    },
    loaded: {
      true: {
        animation: 'none',
        backgroundColor: 'transparent',
      },
      false: {
        animation: 'none',
        backgroundColor: '$themeA8',
      },
    },
    variant: {
      default: {},
      neutral: {
        backgroundColor: '#F3F4F6',
        color: '#333333', // Dark gray, almost black
      },
    },
  },

  defaultVariants: {
    rounded: false,
    size: 'md',
    border: false,
  },
});

const Image = styled('img', {
  height: '100%',
  width: '100%',

  variants: {
    error: {
      true: {
        display: 'none',
      },
      false: {},
    },
    aspect: {
      default: {},
      primary: {
        aspectRatio: 1,
      },
    },
  },
});

const DefaultImg = styled(Text, {
  aspectRatio: 1,
  justifyContent: 'center',

  variants: {
    avatar: {
      xs: {
        fontSize: 12,
        fontWeight: '$medium',
      },
      sm: {
        fontSize: 14,
        fontWeight: '$medium',
      },
      m: {
        fontSize: 30,
        fontWeight: '$medium',
      },
      md: {
        fontSize: 38,
        fontWeight: '$medium',
      },
      lg: {
        fontSize: 46,
        fontWeight: '$medium',
      },
      xl: {
        fontSize: 94,
        fontWeight: '$medium',
      },
      profilePic: {
        fontSize: 145,
        fontWeight: '$medium',
      },
    },
    color: {
      true: {
        color: 'inherit',
      },
      false: {
        color: '$textDefault',
      },
    },
  },

  defaultVariants: {
    color: false,
  },
});

const IconicAction = styled(Icon, {
  position: 'absolute',
  right: 10,
  top: 10,
  padding: 10,
  color: '$themeDefault',

  variants: {
    persistent: {
      true: {
        display: 'block',
      },
      // @ts-ignore
      false: {
        display: 'none',

        [`${Wrapper}:hover &`]: {
          display: 'block',
          borderRadius: '40px 40px',
          backgroundColor: '$whiteA68',
          height: '40px',
          width: '40px',
        },
      },
    },
  },

  defaultVariants: {
    persistent: false,
  },
});

const DisabledAvatarWrapper = styled('div', {
  position: 'absolute',
  bottom: 0,
  right: 0,
  lineHeight: 0,
  transform: 'translate(50%, 50%)',
  backgroundColor: '$dangerDefault',
  width: 15,
  height: 15,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100%',
  border: 'none',
});

const DisabledIcon = styled(Icon, {
  color: '$whiteA96',
  fontSize: 10,
});

export type Variants = Omit<VariantProps<typeof Wrapper>, 'loaded'>;

export type Props = Variants &
  Record<string, any> & {
    a11yLabel?: string;
    color?: boolean;
    firstName?: string;
    lastName?: string;
    initialsFormat?: 'first' | 'firstLast';
    icon?: IconProp;
    showIconicAction?: boolean;
    src?: string | null;
    imageStyles?: Record<string, any>;
    css?: CSS;
  };

const Avatar = ({
  a11yLabel,
  color,
  firstName,
  lastName,
  initialsFormat = 'first',
  icon = faPen,
  showIconicAction = false,
  disabled = false,
  src,
  imageStyles,
  ...variants
}: Props) => {
  const [isError, setIsError] = useState(false);

  const getInitials = () => {
    if (!firstName) return '';
    if (initialsFormat === 'firstLast' && lastName) {
      return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
    }
    return firstName.charAt(0).toUpperCase();
  };

  const handleImgError = useCallback(() => setIsError(true), []);

  return (
    <Wrapper data-testid="avatar-component">
      <AvatarWrapper {...variants} loaded={src ? 'true' : 'false'}>
        {src ? (
          <Image
            alt="Avatar"
            aria-label={a11yLabel || 'Avatar'}
            aspect="primary"
            css={imageStyles}
            error={isError}
            src={src}
            onError={handleImgError}
          />
        ) : (
          <DefaultImg avatar={variants.size} color={color} css={imageStyles}>
            {getInitials()}
          </DefaultImg>
        )}
        {showIconicAction && <IconicAction icon={icon} />}
      </AvatarWrapper>
      {disabled && (
        <DisabledAvatarWrapper>
          <DisabledIcon icon={faTimes} />
        </DisabledAvatarWrapper>
      )}
    </Wrapper>
  );
};

Avatar.Wrapper = Wrapper;
Wrapper.displayName = 'stitches(Avatar.Wrapper)';

Avatar.Image = Image;
Image.displayName = 'stitches(Avatar.Image)';

export default Avatar;
