import { Variants } from '@/components/Alert';
import { GetWorkerQuery, GetOrderQuery } from '@/types/graphql';
import { FormError as UseFormError } from '@area2k/use-form';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { LinkProps } from 'react-router-dom';
import { CustomerAdmin } from '@/types/graphql';

export type Maybe<T> = T | null;

export type RequiredNotNull<T> = { [K in keyof T]: NonNullable<T[K]> };

export type ActionAppearance =
  | 'primary'
  | 'secondary'
  | 'outline'
  | 'clear'
  | 'plain';

export type ActionStatus = 'danger' | 'neutral' | 'success';

export type RouterLocationDescriptor = LinkProps['to'];

export interface FormError extends UseFormError {
  icon?: IconDefinition;
  title: string;
  status?: Variants['status'];
}

export interface BaseAction {
  a11yLabel: string;
  dangerous?: boolean;
  icon?: IconDefinition;
  id?: string;
  label?: string;
  appearance?: ActionAppearance;
}

export interface LinkAction extends BaseAction {
  end?: boolean;
  navigational?: boolean;
  to: RouterLocationDescriptor;
  id?: string;
  disabled?: boolean;
  hide?: boolean;
  onClick?: () => void;
}

export interface AnchorAction extends BaseAction {
  href: string;
  download?: string;
  external?: boolean;
  disabled?: boolean;
}

export interface CallbackAction extends BaseAction {
  disabled?: boolean;
  isLoading?: boolean;
  loadingIcon?: IconDefinition;
  loadingLabel?: string;
  onAction: () => void;
}

declare interface GenericAction {
  a11yLabel?: string;
  disabled?: boolean;
  icon?: IconDefinition;
  CustomIcon?: React.ReactNode;
  id?: string;
  label: string;
  onAction?: () => void;
}

export type GenericActionOrList = GenericAction | ActionList;

export enum InvoiceStatus {
  ALL_INVOICE = 'ALL_INVOICE',
  OPEN = 'OPEN',
  DRAFT = 'DRAFT',
  PAID = 'PAID',
  PAST_DUE = 'PAST_DUE',
  OUTSTANDING = 'OUTSTANDING',
}

export type SkillItem = GetWorkerQuery['worker']['skills'][0];
export type OrderJobItem = GetOrderQuery['order']['jobs'][0];

export type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};

export type CreateJobContactAdminType = {
  id: CustomerAdmin['id'];
  user: Pick<CustomerAdmin['user'], 'id' | 'firstName' | 'lastName'>;
};
