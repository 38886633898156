import { useReactiveVar } from '@apollo/client';
import useModal from '@area2k/use-modal';
import { useFeatureValue } from '@growthbook/growthbook-react';
import React, { useEffect, useMemo, useState } from 'react';

import CreateWorkerModal from './CreateWorkerModal';
import CandidateFilters from './Filters/CandidateFilters';
import WorkerFilters from './Filters/WorkerFilters';
import { getHeaderFields, RenderBody } from './helpers';

import Card from '@/components/Card';
import Page from '@/components/Page';
import Paginator from '@/components/Paginator/Paginator';
import QueryEmptyState from '@/components/QueryEmptyState';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import Tabs, { TabDefinition } from '@/components/Tabs';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { TIME_TO_REFRESH } from '@/constants/general';
import { useListCandidatesQuery, useListWorkersQuery } from '@/graphql';
import { useAppDispatch } from '@/hooks/store';
import useFilters from '@/hooks/useFilters';
import { resetWorkerTableFilters } from '@/store/slices/filtersSlice';
import {
  CandidateFilterSetInput,
  CandidateStatusEnum,
  WorkerFilterSetInput,
} from '@/types/graphql';
import { currentAgencyVar } from '@/util/apollo/cache';

const RELATIONSHIP_TABS: TabDefinition[] = [
  { a11yLabel: 'View candidates', label: 'Candidates' },
  { a11yLabel: 'View applicant', label: 'Applicants' },
  { a11yLabel: 'View in progress', label: 'GravyWorkers in Progress' },
  { a11yLabel: 'View completed', label: 'GravyWorkers Complete' },
];

const Workers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [tab, setTab] = useState(0);
  const ratingEnabled = useFeatureValue(FEATURE_TOGGLE.WorkerRating, false);

  const [showCreateModal, hideCreateModal] = useModal(
    () => <CreateWorkerModal hideModal={hideCreateModal} />,
    [],
  );
  const dispatch = useAppDispatch();

  const candidateFilters = useFilters<CandidateFilterSetInput>({});
  const workerFilters = useFilters<WorkerFilterSetInput>({});

  const candidateFinalFilters = useMemo<CandidateFilterSetInput>(() => {
    const value = { ...candidateFilters.filters };
    if (candidateFilters.debouncedQuery !== '') {
      value.query = { value: candidateFilters.debouncedQuery };
    }
    return value;
  }, [candidateFilters]);

  const workerFinalFilters = useMemo<CandidateFilterSetInput>(() => {
    const value = { ...workerFilters.filters };
    if (workerFilters.debouncedQuery !== '') {
      value.query = { value: workerFilters.debouncedQuery };
    }
    return value;
  }, [workerFilters]);

  const currentAgency = useReactiveVar(currentAgencyVar);
  const candidates = useListCandidatesQuery({
    variables: {
      agencyId: currentAgency!.id,
      filters: candidateFinalFilters,
      page: currentPage,
      perPage: itemsPerPage,
    },
    pollInterval: TIME_TO_REFRESH,
  });
  const workers = useListWorkersQuery({
    variables: {
      agencyId: currentAgency!.id,
      filters: workerFinalFilters,
      page: currentPage,
      perPage: itemsPerPage,
    },
    fetchPolicy: 'network-only',
  });

  const workersItems = workers.data?.agency.workers.items || [];

  const candidatesItems = candidates.data?.agency.candidates.items || [];

  const maxPagesWorker = workers.data?.agency.workers.pageInfo.totalItems || 0;

  const maxPagesCandidate =
    candidates.data?.agency.candidates.pageInfo.totalItems || 0;

  const getItemsData = () => (tab <= 2 ? candidatesItems : workersItems);

  const getItemsLength = () => (tab <= 2 ? maxPagesCandidate : maxPagesWorker);

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleNumberItemsChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setCurrentPage(1);
    setItemsPerPage(parseInt(event.target.value));
  };

  useEffect(() => {
    setCurrentPage(1);
    switch (tab) {
      case 0:
        candidateFilters.setFilter('statusGroup', [
          { value: CandidateStatusEnum.UNVERIFIED },
          { value: CandidateStatusEnum.VERIFIED },
        ]);
        break;
      case 1:
        candidateFilters.setFilter('statusGroup', [
          { value: CandidateStatusEnum.APPLIED },
          { value: CandidateStatusEnum.ONBOARDED },
          { value: CandidateStatusEnum.INTERVIEWING },
        ]);
        break;
      case 2:
        candidateFilters.setFilter('statusGroup', [
          { value: CandidateStatusEnum.START_HIRE },
        ]);
        break;
    }
  }, [tab]);

  useEffect(() => {
    if (candidateFilters.query !== '' || workerFilters.query !== '') {
      setCurrentPage(1);
    }
  }, [candidateFilters.query, workerFilters.query]);

  useEffect(() => {
    return () => {
      dispatch(resetWorkerTableFilters());
    };
  }, []);

  return (
    <Page size="full" title="GravyWorkers">
      <Card>
        <Tabs
          fit
          selected={tab}
          showTabOnMobile={false}
          tabs={RELATIONSHIP_TABS}
          onSelect={setTab}
        />
        <Card.Section>
          {tab < 3 ? (
            <CandidateFilters
              query={candidateFilters.query}
              tab={tab}
              onQueryChange={candidateFilters.setQuery}
            />
          ) : (
            <WorkerFilters
              filters={workerFilters.filters}
              query={workerFilters.query}
              updatePageNumber={setCurrentPage}
              onChangeFilter={workerFilters.setFilter}
              onClearAll={workerFilters.clearAll}
              onClearFilter={workerFilters.clearFilter}
              onQueryChange={workerFilters.setQuery}
            />
          )}
        </Card.Section>
        <Table>
          <TableHeader fields={getHeaderFields(tab)} />
          <RenderBody
            items={getItemsData()}
            ratingEnabled={ratingEnabled}
            tab={tab}
          />
        </Table>
        {getItemsData().length === 0 && (
          <QueryEmptyState
            query={candidates || workers}
            text={`No ${RELATIONSHIP_TABS[tab].label} were found, create one above.`}
            title={`No ${RELATIONSHIP_TABS[tab].label}`}
          />
        )}
      </Card>
      {getItemsData().length > 0 && (
        <Paginator
          currentPage={currentPage}
          handleNumberItemsChange={handleNumberItemsChange}
          handlePageChange={handlePageChange}
          itemsLength={getItemsLength()}
          itemsPerPage={itemsPerPage}
        />
      )}
    </Page>
  );
};

export default Workers;
