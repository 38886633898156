import { useReactiveVar } from '@apollo/client';
import useModal from '@area2k/use-modal';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EventEmitter from 'reactjs-eventemitter';
import SignOutModal from './modals/SignOutModal';

import Avatar from '@/components/Avatar';
import HeroIcon from '@/components/HeroIcon';
import Stack from '@/components/Stack';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import Text from '@/elements/Text';
import {
  useDeviceLogoutMutation,
  useGetCurrentActorLazyQuery,
  useGetNotificationsLazyQuery,
} from '@/graphql';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import useMediaQuery from '@/hooks/useMediaQuery';
import useTenantAdminProfile from '@/hooks/useOpenProfile';
import { Role } from '@/routes/PrivateRoute';
import ROUTES from '@/routes/routes';
import {
  selectSidebarActive,
  setRefetchDashboardData,
  toggleSidebar,
  setSidebarVisibility,
} from '@/store/slices/layoutSlice';
import { GenericAction } from '@/types';
import { CurrentTenantAdminFragment, Notification } from '@/types/graphql';
import useAnalytics from '@/util/analytics';
import { currentAdminVar, currentTenantVar } from '@/util/apollo/cache';
import { EventEmitterTopics } from '@/util/constants';
import { isWeb } from '@/util/platform';
import { useFeatureValue } from '@growthbook/growthbook-react';
import {
  ArrowRightStartOnRectangleIcon,
  Bars3Icon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import AppButtonV2 from '../AppButtonV2';
import DropdownMenuV2 from '@/components/DropdownMenuV2';
import NotificationComponent from '../NotificationPopup';
import {
  BellWrapper,
  Branding,
  BurgerMenu,
  DropdownWrapper,
  LeftSection,
  Logo,
  Profile,
  RedDot,
  Wrapper,
} from './styles';
import useDisableBodyScroll from '@/hooks/useDisableBodyScroll';

const AppNavbarV2 = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [eventEmitterSubscribed, setEventEmitterSubscribed] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [showRedDot, setShowRedDot] = useState(false);
  const ReleaseNotesAdmin = useFeatureValue(
    FEATURE_TOGGLE.ReleaseNotesAdmin,
    false,
  );

  const [getNotification, { loading }] = useGetNotificationsLazyQuery({
    variables: {
      page: 1,
      perPage: 10,
    },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (data.notifications.items) {
        setPage(1);
        setMaxPage(data.notifications.pageInfo.totalPages);
        setNotifications(data.notifications.items);
      }
    },
    onError(error) {
      console.error(error);
    },
  });

  const loadMoreNotifications = () => {
    if (page + 1 <= maxPage && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const [getCurrentWorker] = useGetCurrentActorLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setShowRedDot(
        data?.currentActor?.user?.notificationStats?.release_notes > 0 || false,
      );
    },
    onError(error) {
      console.error(error);
    },
  });

  const reloadData = async () => {
    await getNotification();
    await getCurrentWorker();
  };

  useEffect(() => {
    try {
      if (!eventEmitterSubscribed) {
        EventEmitter.subscribe(
          EventEmitterTopics.RELEASE_NOTES_PUBLISHED,
          () => {
            reloadData();
          },
        );
        setEventEmitterSubscribed(true);
      }
    } catch (e) {
      console.error('Error in subscribing to event emitter', e);
    }
  }, []);

  useEffect(() => {
    if (page > 1) {
      getNotification({
        variables: {
          page: page,
        },
        onCompleted(data) {
          if (data.notifications.items) {
            setMaxPage(data.notifications.pageInfo.totalPages);
            setNotifications((prevNotifications) => [
              ...prevNotifications,
              ...data.notifications.items,
            ]);
          }
        },
        onError(error) {
          console.error('Error fetching notifications:', error);
        },
      });
    } else {
      reloadData().catch((error) => {
        console.error('Error reloading data:', error);
      });
    }
  }, [page]);

  const showSidebar = useAppSelector((state) => selectSidebarActive(state));
  const openAppLinkMobile = useFeatureValue(FEATURE_TOGGLE.OpenAppLink, false);

  const dispatch = useAppDispatch();

  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const isSmallScreen = useMediaQuery('(max-width: 899px)');

  const currentTenant = useReactiveVar(currentTenantVar);
  const currentAdmin = useReactiveVar(currentAdminVar);

  const { resetGoogleAnalytics } = useAnalytics();
  const [openNotification, setOpenNotification] = useState(false);

  const showNotification =
    ReleaseNotesAdmin &&
    ((currentAdmin?.role === Role.CUSTOMER_ADMIN &&
      currentAdmin.customer?.status > 0) ||
      currentAdmin?.role === Role.TENANT_ADMIN);

  const [deviceLogout] = useDeviceLogoutMutation();
  const [showSignOutModal, hideSignOutModal] = useModal(
    () => (
      <SignOutModal
        onConfirm={async () => {
          window?.HubSpotConversations?.widget?.remove();
          await deviceLogout();
          resetGoogleAnalytics();
          navigate(ROUTES.signOut);
        }}
        onHide={hideSignOutModal}
      />
    ),
    [],
  );

  const { showProfile } = useTenantAdminProfile(
    currentAdmin as CurrentTenantAdminFragment,
  );

  const hideRedDot = () => {
    setShowRedDot(false);
  };

  const readNotification = async (id: string) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) => {
        if (notification.id === id) {
          getCurrentWorker();
          return { ...notification, isRead: true };
        }
        return notification;
      }),
    );
  };

  const dropdownActions = useMemo((): GenericAction[][] => {
    return [
      [
        {
          a11yLabel: 'My Profile',
          CustomIcon: <HeroIcon icon={UserCircleIcon} />,
          label: 'My Profile',
          onAction: showProfile,
        },
        {
          a11yLabel: 'Log Out',
          CustomIcon: <HeroIcon icon={ArrowRightStartOnRectangleIcon} />,
          label: 'Log Out',
          onAction: showSignOutModal,
          id: 'sign-out',
        },
      ],
    ];
  }, [currentAdmin, showProfile]);

  const toggleNotification = () => {
    if (showSidebar && isSmallScreen && !isWeb()) {
      dispatch(setSidebarVisibility(false));
    }
    setOpenNotification(!openNotification);
  };

  const handleProfileOpenChange = (isOpen: boolean) => {
    if (isOpen && showSidebar && isSmallScreen) {
      dispatch(setSidebarVisibility(false));
    }
    setIsProfileOpen(isOpen);
  };

  const ProfileHeader = () => (
    <Stack vertical gap={14}>
      <Text size="md" weight="semibold" className="mb-4">
        Account
      </Text>
      <Stack gap={12} align="center">
        <Avatar
          color
          firstName={currentAdmin?.user.firstName}
          lastName={currentAdmin?.user.lastName}
          size="sm"
          useInitials={true}
          variant="neutral"
          initialsFormat="firstLast"
          css={{ width: '40px', height: '40px', fontSize: '16px' }}
        />
        <Stack vertical gap={0}>
          <Text size="sm" weight="medium">
            {currentAdmin?.user.firstName} {currentAdmin?.user.lastName}
          </Text>
          <Text size="sm" color="lighter" className="mt-0.5">
            {currentAdmin?.user?.email}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );

  const [isProfileOpen, setIsProfileOpen] = useState(false);

  useDisableBodyScroll(showSidebar && !isWeb);

  return (
    <Wrapper>
      <LeftSection>
        <BurgerMenu
          show={showSidebar}
          onClick={() => dispatch(toggleSidebar())}
        >
          <HeroIcon icon={showSidebar ? XMarkIcon : Bars3Icon} />
        </BurgerMenu>
        {openAppLinkMobile && isWeb() && phoneOnly && <AppButtonV2 />}
      </LeftSection>
      <Branding
        to={ROUTES.basePath}
        onClick={() => {
          if (window.location.pathname !== ROUTES.basePath) {
            dispatch(setRefetchDashboardData(true));
          }
        }}
      >
        <Logo
          src={require('@/assets/img/gravywork-logo.svg')}
          alt="GravyWork"
        />
      </Branding>

      <DropdownWrapper>
        {showNotification && (
          <BellWrapper onClick={toggleNotification}>
            <NotificationsOutlinedIcon fontSize="medium" htmlColor="#1a1a1a" />
            {showRedDot && <RedDot />}
          </BellWrapper>
        )}
        <DropdownMenuV2
          actions={dropdownActions}
          justify="right"
          margin="rightLg"
          size={phoneOnly ? 'sm' : 'md'}
          header={<ProfileHeader />}
          onOpenChange={handleProfileOpenChange}
        >
          <Profile id="profile-dropdown" isOpen={isProfileOpen}>
            <Avatar
              color
              firstName={currentAdmin?.user.firstName}
              lastName={currentAdmin?.user.lastName}
              size="sm"
              useInitials={true}
              variant="neutral"
              initialsFormat="firstLast"
              src={currentAdmin?.avatarUrl}
            />
          </Profile>
        </DropdownMenuV2>
      </DropdownWrapper>
      <NotificationComponent
        loadMoreNotifications={loadMoreNotifications}
        hideRedDot={hideRedDot}
        data={notifications}
        open={openNotification}
        loading={loading}
        enableReadAll={showRedDot}
        readNotification={readNotification}
        toggleNotification={toggleNotification}
      />
    </Wrapper>
  );
};

export default AppNavbarV2;
