import Popover from '@mui/material/Popover';
import { format } from 'date-fns';

import { Body } from '@/components/Typography';
import Text from '@/elements/Text';

const CustomerSettingTooltip = ({
  open,
  anchorEl,
  handlePopoverClose,
  message,
}: any) => {
  const rowStyle = {
    display: 'block',
    padding: '0px 12px 0px 16px',
  };
  const cellStyle = {
    color: 'black',
    lineHeight: 'normal',
    letterSpacing: '.17px',
    padding: '0px 0px',
  };

  return (
    <Popover
      disableRestoreFocus
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      slotProps={{
        paper: {
          style: {
            maxWidth: '269px',
            width: '269px',
            boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.12)',
            border: '1px solid #E2E2E2',
          },
          className: 'afterArrow',
        },
      }}
      sx={{
        pointerEvents: 'none',
        left: '103px !important',
        top: '-35px',
        zIndex: 10001,
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={handlePopoverClose}
    >
      <Body
        css={{
          background: '#DFF3D8',
          padding: '12px 16px',
        }}
        weight={'bold'}
      >
        Detail
      </Body>
      <div style={{ padding: '12px 0px 20px 0px', fontSize: '12px' }}>
        <div style={rowStyle}>
          <div style={cellStyle}>
            <Text>{message}</Text>
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default CustomerSettingTooltip;
