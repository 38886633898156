import { SubmitHelpers } from '@area2k/use-form';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { isEqual } from 'lodash';
import { useCallback, useState, useMemo } from 'react';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal, { HeaderDialog, TitleDialog } from '@/components/Modal';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import TextInput from '@/components/TextInput';
import { Small } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import Form from '@/form';
import OptionField from '@/form/OptionField';
import TextField from '@/form/TextField';
import TextSelectField from '@/form/TextSelectField';
import {
  useCheckDuplicateCustomerNameQuery,
  useListAgencyBadgeCertificateQuery,
  useUpdateCustomerMutation,
} from '@/graphql';
import styled from '@/styles';
import {
  DefaultDueDateEnum,
  GetCustomerQuery,
  ListAgencyBadgeCertificateQuery,
  TaxTypeEnum,
} from '@/types/graphql';
import {
  JOB_TAX_TYPES,
  TAX_TYPE_LABELS,
  taxOptions,
  MARKUP_PERCENTAGE_FIELD_ERROR,
  CONFIGURABLE_CHECKIN_FIELD_ERROR,
  CLIENT_BADGE_UPDATED,
  CONFIGURABLE_SCRUTINY_CHECKOUT_TIME_ERROR,
  STRICT_LOCATION_POLICY_INFO,
  STRICT_TIME_POLICY_INFO,
} from '@/util/constants';
import { stringToEnumInvoiceDueDate } from '@/util/date';
import { handleMutationFormError } from '@/util/error';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import useAuth from '@/hooks/useAuth';
import { CertificateTypeEnum } from '@/types/graphql';
import { sortBy } from '@/util/array';
import Tag from '@/components/Tag';
import useAnalytics from '@/util/analytics';
import { GAEvent } from '@/constants/gaevents';
import BadgesAutocomplete from '@/components/Autocomplete/BadgesAutocomplete';
import Tabs, { TabDefinition } from '@/components/Tabs';
import { InfoItalicIcon } from '@/assets/icons';
import CustomerSettingTooltip from './CustomerSettingTooltip';
import {
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import ScrutinyDialogPhoneView from '@/routes/Agency/ScrutinyDialogPhoneView';

const LabelText = styled('p', {
  fontSize: '13px',
  fontWeight: 600,
  color: '#332F2D',
  marginBottom: '5px',
});

const StyledInfoIcon = styled(InfoItalicIcon, {
  color: '#5C5C5C',
  width: '20px',
});

export type Props = {
  customer: GetCustomerQuery['customer'];
  hideModal: () => void;
};

type FormValues = {
  invoiceDueDate: DefaultDueDateEnum;
  name: string;
  enableLTA: boolean;
  markupPercent: number;
  checkInRadiusFeet: number;
  certificateIds: string[];
  checkOutRadiusFeet: number;
  scrutinyCheckoutDuration: number;
  enableStrictLocationPolicy: boolean;
  enableStrictTimePolicy: boolean;
};

type CertItem =
  ListAgencyBadgeCertificateQuery['agency']['badgeCertificates'][0];

const invoiceDueDateOptions = [
  { label: '10 days', value: DefaultDueDateEnum.INVOICE_DUE_10_DAYS },
  { label: '15 days', value: DefaultDueDateEnum.INVOICE_DUE_15_DAYS },
  { label: '30 days', value: DefaultDueDateEnum.INVOICE_DUE_30_DAYS },
  { label: '45 days', value: DefaultDueDateEnum.INVOICE_DUE_45_DAYS },
  { label: '60 days', value: DefaultDueDateEnum.INVOICE_DUE_60_DAYS },
];

const UpdateClientModalRevamp = ({ customer, hideModal }: Props) => {
  const TABS: TabDefinition[] = [
    {
      a11yLabel: 'General',
      label: 'General',
    },
    { a11yLabel: 'Timesheet', label: 'Timesheet' },
  ];
  const invoiceDueDate: DefaultDueDateEnum = stringToEnumInvoiceDueDate(
    customer.invoiceDueDate,
  );

  const formatCurrentTaxType = (taxType: string) => {
    switch (taxType) {
      case JOB_TAX_TYPES.all:
        return TaxTypeEnum.ALL;
      case JOB_TAX_TYPES.w2:
        return TaxTypeEnum.TAX_W2;
      default:
        return taxType;
    }
  };

  const initialValues: FormValues = {
    invoiceDueDate,
    name: customer.name,
    enableLTA: customer.ltaAllowed || false,
    markupPercent: customer.markupPercent,
    checkInRadiusFeet: customer.checkInRadiusFeet,
    certificateIds: customer.certificates.map((cert) => cert.id),
    checkOutRadiusFeet: customer.checkOutRadiusFeet || 0,
    scrutinyCheckoutDuration: customer.scrutinyCheckoutDuration || 0,
    enableStrictLocationPolicy: customer.checkOutRadiusFeet > 0,
    enableStrictTimePolicy: customer.scrutinyCheckoutDuration > 0,
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [selectedBadgeCertificates, setSelectedBadgeCertificates] = useState<
    CertItem[]
  >(customer.certificates);
  const [error, setError] = useState<{ message: string } | null>(null);

  const [badgeUpdated, setBadgeUpdated] = useState<boolean>(false);
  const { logEvent } = useAnalytics();

  const [certQuery, setCertQuery] = useState('');
  const debouncedCertQuery = useDebouncedValue(certQuery);
  const [customerTaxType, setCustomerTaxType] = useState(
    formatCurrentTaxType(customer.jobTaxType ?? JOB_TAX_TYPES.all),
  );
  const [updateCustomer, { loading: isLoading }] = useUpdateCustomerMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(customer),
        fields: {
          jobTaxType() {},
          ltaAllowed() {},
          certificates() {},
        },
      });
    },
  });

  const { data: checkCustomerNameData } = useCheckDuplicateCustomerNameQuery({
    fetchPolicy: 'no-cache',
    variables: { clientName: formValues.name },
  });

  const customerNameDuplicate =
    checkCustomerNameData && checkCustomerNameData.customerNameDuplicate;

  const workerBadgeClient = useFeatureValue(
    FEATURE_TOGGLE.WorkerBadgeClient,
    false,
  );

  const handleFormValuesChange = (fieldContext, fieldId: keyof FormValues) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }));
  };

  const handleMarkUpValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target) return;

    let inputMarkupValue = e.target.value;
    const inputElement = e.target;
    let cursorPosition = inputElement.selectionStart ?? 0;

    let markUpValueFormatted = inputMarkupValue.replace('%', '');

    if (!/^\d*$/.test(markUpValueFormatted)) {
      return;
    }

    const isBackspace =
      (e.nativeEvent as InputEvent).inputType === 'deleteContentBackward';

    if (isBackspace && cursorPosition === inputMarkupValue.length) {
      if (markUpValueFormatted.length > 0) {
        markUpValueFormatted = markUpValueFormatted.slice(0, -1);
      }
    }

    handleFormValuesChange({ value: markUpValueFormatted }, 'markupPercent');

    setTimeout(() => {
      inputElement.value = `${markUpValueFormatted}%`;
      inputElement.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  const handleCheckinDistanceChange = (inputCheckinDistanceValue: string) => {
    if (!/^\d*$/.test(inputCheckinDistanceValue)) {
      return;
    }
    handleFormValuesChange(
      { value: inputCheckinDistanceValue },
      'checkInRadiusFeet',
    );
    handleFormValuesChange(
      { value: inputCheckinDistanceValue },
      'checkOutRadiusFeet',
    );
  };

  const handleSelectCertificate = useCallback(
    (item) => {
      setBadgeUpdated(true);
      setSelectedBadgeCertificates([
        ...selectedBadgeCertificates,
        item.selectedItem,
      ]);
      logEvent(GAEvent.BadgeAddedOnClient);
      handleFormValuesChange(
        {
          value: [...selectedBadgeCertificates, item.selectedItem].map(
            (cert) => cert.id,
          ),
        },
        'certificateIds',
      );
    },
    [selectedBadgeCertificates, setSelectedBadgeCertificates],
  );

  const showMarkUpPriceFieldError = formValues.markupPercent < 1;
  const checkinDistanceError =
    formValues.checkInRadiusFeet < 250 || formValues.checkInRadiusFeet > 10000;

  const scrutinyCheckoutDurationError =
    formValues.enableStrictTimePolicy &&
    (formValues.scrutinyCheckoutDuration < 1 ||
      formValues.scrutinyCheckoutDuration > 120);
  const formSubmitDisabledCheck = () => {
    const markupError = customer?.markupPercent
      ? showMarkUpPriceFieldError
      : false;

    return markupError || checkinDistanceError || scrutinyCheckoutDurationError;
  };

  const handleSubmit = useCallback(
    async (_values: any, { setFormError }: SubmitHelpers) => {
      try {
        const {
          enableLTA,
          invoiceDueDate,
          name,
          markupPercent,
          checkInRadiusFeet,
          certificateIds,
          checkOutRadiusFeet,
          scrutinyCheckoutDuration,
        } = formValues;
        if (
          isEqual(formValues, initialValues) &&
          customerTaxType ===
            (customer.jobTaxType
              ? (customer.jobTaxType as TaxTypeEnum)
              : TaxTypeEnum.ALL)
        ) {
          return hideModal();
        }
        if (customerNameDuplicate) {
          if (formValues.name !== initialValues.name) {
            return setError({ message: 'This client name is already in use.' });
          } else {
            setError(null);
          }
        }
        if (error === null) {
          await updateCustomer({
            variables: {
              customerId: customer.id,
              taxType: customerTaxType as TaxTypeEnum,
              ltaAllowed: enableLTA,
              invoiceDueDate: invoiceDueDate,
              name: name,
              markupPercent: Number(markupPercent),
              checkInRadiusFeet: Number(checkInRadiusFeet),
              certificateIds: certificateIds,
              checkOutRadiusFeet: Number(checkOutRadiusFeet),
              scrutinyCheckoutDuration: Number(scrutinyCheckoutDuration),
            },
          });

          hideModal();
        }
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
          errorMap: {
            all: (gqlError) => ({
              title: gqlError.name,
              message: gqlError.message,
              status: 'danger',
            }),
          },
        });
      }
    },
    [customer.id, customerNameDuplicate, formValues, customerTaxType],
  );

  const { currentAgency, currentAdminIsCustomerAdmin } = useAuth();

  const { data } = useListAgencyBadgeCertificateQuery({
    skip: !workerBadgeClient || currentAdminIsCustomerAdmin,
    variables: {
      agencyId: currentAgency!.id,
    },
  });

  const badgeItems: CertItem[] = useMemo(() => {
    if (workerBadgeClient && data) {
      const filterCertificate = data.agency.badgeCertificates.filter((cert) => {
        if (
          cert.certificateType === CertificateTypeEnum.CERTIFICATE ||
          (selectedBadgeCertificates.length > 0 &&
            selectedBadgeCertificates.some((badge) => badge.id === cert.id))
        ) {
          return false;
        }

        return cert.name
          .toLowerCase()
          .includes(debouncedCertQuery.toLowerCase());
      });

      return sortBy(filterCertificate, 'name');
    }
    return [];
  }, [data, selectedBadgeCertificates, debouncedCertQuery, workerBadgeClient]);

  const handleCertRemove = (id: string) => {
    logEvent(GAEvent.BadgeRemovedOnClient);
    setBadgeUpdated(true);
    setSelectedBadgeCertificates(
      selectedBadgeCertificates.filter((badge) => badge.id !== id),
    );
    handleFormValuesChange(
      {
        value: [
          ...selectedBadgeCertificates
            .filter((badge) => badge.id !== id)
            .map((cert) => cert.id),
        ],
      },
      'certificateIds',
    );
  };

  const handleLocationToggleChange = ({ value }: { value: boolean }) => {
    handleFormValuesChange({ value }, 'enableStrictLocationPolicy');
    const checkOutRadiusFeet = value ? formValues.checkInRadiusFeet : 0;
    handleFormValuesChange({ value: checkOutRadiusFeet }, 'checkOutRadiusFeet');
  };

  const handleTimeToggleChange = ({ value }: { value: boolean }) => {
    handleFormValuesChange({ value }, 'enableStrictTimePolicy');
    if (!value) {
      handleFormValuesChange({ value: 0 }, 'scrutinyCheckoutDuration');
    }
  };

  const handleScrutinyCheckoutDurationChange = (inputValue: string) => {
    if (!/^\d*$/.test(inputValue)) {
      return;
    }
    handleFormValuesChange({ value: inputValue }, 'scrutinyCheckoutDuration');
  };

  const phoneOnly = useMediaQuery('(max-width: 790px)');

  const [tab, setTab] = useState(0);

  const [scrutinyDistanceAnchorTooltip, setScrutinyDistanceAnchorTooltip] =
    useState(null);
  const handleScrutinyDistancePopoverOpen = (event) => {
    setScrutinyDistanceAnchorTooltip(event.currentTarget);
  };
  const handleScrutinyDistancePopoverClose = () => {
    setScrutinyDistanceAnchorTooltip(null);
  };
  const openScrutinyDistanceTooltip = Boolean(scrutinyDistanceAnchorTooltip);

  const [scrutinyDurationAnchorTooltip, setScrutinyDurationAnchorTooltip] =
    useState(null);
  const handleScrutinyDurationPopoverOpen = (event) => {
    setScrutinyDurationAnchorTooltip(event.currentTarget);
  };
  const handleScrutinyDurationPopoverClose = () => {
    setScrutinyDurationAnchorTooltip(null);
  };
  const openScrutinyDurationTooltip = Boolean(scrutinyDurationAnchorTooltip);

  const getClientSetting = () => {
    return (
      <>
        <Tabs
          minWidth={'100px'}
          selected={tab}
          showTabOnMobile={true}
          tabs={TABS}
          wrapperStyle={{
            width: '100%',
            padding: '0px',
          }}
          onSelect={setTab}
        />
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <Card.Section>
            <div
              style={
                phoneOnly
                  ? { overflow: 'auto', height: 'calc(80vh - 207px)' }
                  : { paddingBottom: '20px' }
              }
            >
              {error && (
                <Alert
                  description={error.message}
                  icon={faExclamationTriangle}
                  status="warning"
                  title="Something went wrong"
                />
              )}

              {tab === 0 ? (
                <>
                  <LabelText>Client Name</LabelText>
                  <TextField
                    autoFocus
                    required
                    callback={(fieldContext) => {
                      handleFormValuesChange(fieldContext, 'name');
                      if (error) {
                        setError(null);
                      }
                    }}
                    fieldId="name"
                    placeholder="Client name"
                  />
                  <Stack style={{ alignItems: 'start' }}>
                    <Stack vertical gap={0}>
                      <LabelText>Net Terms</LabelText>
                      <TextSelectField
                        callback={(fieldContext) => {
                          handleFormValuesChange(
                            fieldContext,
                            'invoiceDueDate',
                          );
                        }}
                        fieldId="invoiceDueDate"
                        options={invoiceDueDateOptions}
                      />
                    </Stack>

                    <Stack vertical gap={0}>
                      <LabelText>Set the Markup</LabelText>
                      <TextInput
                        data-testid="markup-value-input"
                        value={`${formValues.markupPercent}%`}
                        onChange={handleMarkUpValueChange}
                      />

                      {showMarkUpPriceFieldError && (
                        <Small color="danger" role="alert">
                          {MARKUP_PERCENTAGE_FIELD_ERROR}
                        </Small>
                      )}
                    </Stack>
                  </Stack>
                  <Stack style={{ alignItems: 'start' }}>
                    <Stack style={{ marginBottom: '20px' }} vertical>
                      <LabelText>Tax Type</LabelText>
                      <Stack>
                        {taxOptions.map((option) => (
                          <Option
                            key={option}
                            appearance="bullseye"
                            checked={customerTaxType === option}
                            id={`${taxOptions.indexOf(option)}`}
                            label={TAX_TYPE_LABELS[option]}
                            style={{ marginRight: '20px' }}
                            type="radio"
                            value={option}
                            onChange={(ev) =>
                              setCustomerTaxType(ev.currentTarget.value)
                            }
                          />
                        ))}
                      </Stack>
                    </Stack>

                    <Stack vertical>
                      <LabelText>LTA</LabelText>
                      <OptionField
                        appearance="switch"
                        callback={(fieldContext) => {
                          handleFormValuesChange(fieldContext, 'enableLTA');
                        }}
                        fieldId="enableLTA"
                        label="Enable LTA"
                      />
                    </Stack>
                  </Stack>

                  {workerBadgeClient && (
                    <>
                      <LabelText>Badge Requirements</LabelText>
                      <Stack
                        vertical
                        gap="8px"
                        style={{ marginTop: '5px', marginBottom: '20px' }}
                      >
                        <div style={{ width: '100%' }}>
                          <BadgesAutocomplete
                            fixedSelectHeight="auto"
                            id="certificate"
                            itemToKey={(item) => item.id}
                            itemToString={(item) => (item ? item.name : '')}
                            items={badgeItems}
                            placeholder="Select Badges"
                            selectedItem={null}
                            onInputValueChange={({ inputValue }) =>
                              setCertQuery(inputValue || '')
                            }
                            onSelectedItemChange={handleSelectCertificate}
                          />
                        </div>
                        {badgeUpdated && (
                          <Alert
                            css={{ marginBottom: '0px', marginTop: '5px' }}
                            description={CLIENT_BADGE_UPDATED}
                            icon={faExclamationTriangle}
                            status="warning"
                            title="Please Note"
                          />
                        )}
                        {selectedBadgeCertificates.length > 0 && (
                          <Stack style={{ marginTop: '5px' }} vertical>
                            {selectedBadgeCertificates.map((badge) => (
                              <Tag
                                key={badge.id}
                                label={badge.name}
                                icon={badge.imageUrl}
                                showIcon={true}
                                style={{ backgroundColor: '#e0eaff' }}
                                onRemove={() => handleCertRemove(badge.id)}
                              />
                            ))}
                          </Stack>
                        )}
                      </Stack>
                    </>
                  )}
                </>
              ) : (
                <>
                  <LabelText style={{ fontSize: '18px', marginBottom: '10px' }}>
                    Time Settings
                  </LabelText>
                  <Stack vertical>
                    <Stack style={{ alignItems: 'center' }}>
                      <LabelText style={{ marginBottom: 0 }}>
                        Strict Time Policy
                      </LabelText>
                      {phoneOnly ? (
                        <>
                          <StyledInfoIcon
                            onClick={handleScrutinyDurationPopoverOpen}
                          />
                          <ScrutinyDialogPhoneView
                            headerText="Strict Time Policy"
                            infoText={STRICT_TIME_POLICY_INFO}
                            open={openScrutinyDurationTooltip}
                            onClose={handleScrutinyDurationPopoverClose}
                          />
                        </>
                      ) : (
                        <>
                          <StyledInfoIcon
                            onMouseEnter={handleScrutinyDurationPopoverOpen}
                            onMouseLeave={handleScrutinyDurationPopoverClose}
                          />
                          <CustomerSettingTooltip
                            anchorEl={scrutinyDurationAnchorTooltip}
                            handlePopoverClose={
                              handleScrutinyDurationPopoverClose
                            }
                            open={openScrutinyDurationTooltip}
                            message={STRICT_TIME_POLICY_INFO}
                          />
                        </>
                      )}
                    </Stack>
                    <OptionField
                      appearance="switch"
                      callback={(fieldContext) => {
                        handleTimeToggleChange(fieldContext);
                      }}
                      fieldId="enableStrictTimePolicy"
                      label="Manual approval for excess time"
                    />
                  </Stack>

                  <LabelText>Check out time limit (min)</LabelText>
                  <TextInput
                    data-testid="checkout-time-limit-input"
                    placeholder="Enter a values between 1-120"
                    value={formValues.scrutinyCheckoutDuration}
                    disabled={!formValues.enableStrictTimePolicy}
                    onChange={(e) => {
                      handleScrutinyCheckoutDurationChange(e.target.value);
                    }}
                  />
                  {scrutinyCheckoutDurationError && (
                    <Small color="danger" role="alert">
                      {CONFIGURABLE_SCRUTINY_CHECKOUT_TIME_ERROR}
                    </Small>
                  )}

                  <LabelText
                    style={{
                      fontSize: '18px',
                      marginBottom: '10px',
                      marginTop: '24px',
                    }}
                  >
                    Check-in and Out Settings
                  </LabelText>

                  {
                    <>
                      <LabelText>Check In Radius (ft)</LabelText>
                      <Stack
                        vertical
                        gap="8px"
                        style={{ marginTop: '5px', marginBottom: '20px' }}
                      >
                        <TextInput
                          data-testid="checkin-distance-input"
                          value={formValues.checkInRadiusFeet}
                          width="100%"
                          onChange={(e) => {
                            handleCheckinDistanceChange(e.target.value);
                          }}
                          step={1}
                        />
                        {checkinDistanceError && (
                          <Small color="danger" role="alert">
                            {CONFIGURABLE_CHECKIN_FIELD_ERROR}
                          </Small>
                        )}
                      </Stack>
                    </>
                  }

                  <Stack vertical>
                    <Stack style={{ alignItems: 'center' }}>
                      <LabelText style={{ marginBottom: 0 }}>
                        Strict Location Policy
                      </LabelText>

                      {phoneOnly ? (
                        <>
                          <StyledInfoIcon
                            onClick={handleScrutinyDistancePopoverOpen}
                          />
                          <ScrutinyDialogPhoneView
                            headerText="Strict Location Policy"
                            infoText={STRICT_LOCATION_POLICY_INFO}
                            open={openScrutinyDistanceTooltip}
                            onClose={handleScrutinyDistancePopoverClose}
                          />
                        </>
                      ) : (
                        <>
                          <StyledInfoIcon
                            onMouseEnter={handleScrutinyDistancePopoverOpen}
                            onMouseLeave={handleScrutinyDistancePopoverClose}
                          />
                          <CustomerSettingTooltip
                            anchorEl={scrutinyDistanceAnchorTooltip}
                            handlePopoverClose={
                              handleScrutinyDistancePopoverClose
                            }
                            open={openScrutinyDistanceTooltip}
                            message={STRICT_LOCATION_POLICY_INFO}
                          />
                        </>
                      )}
                    </Stack>
                    <OptionField
                      appearance="switch"
                      callback={(fieldContext) => {
                        handleLocationToggleChange(fieldContext);
                      }}
                      fieldId="enableStrictLocationPolicy"
                      label="Manual approval for check-out beyond radius"
                    />
                  </Stack>
                </>
              )}
            </div>
            <Stack justify="end">
              <Button
                a11yLabel="Submit form"
                disabled={formSubmitDisabledCheck()}
                id="btn-save"
                isLoading={isLoading}
                label="Save"
                style={{ width: '100%' }}
                type="submit"
              />
            </Stack>
          </Card.Section>
        </Form>
      </>
    );
  };

  return (
    <>
      {phoneOnly ? (
        <Dialog
          PaperProps={{
            style: {
              maxHeight: '80vh',
              borderRadius: '15px',
            },
          }}
          aria-labelledby="second-options-dialog"
          fullScreen={true}
          open={true}
          sx={{
            '& .MuiDialog-container': {
              alignItems: 'end',
              marginBottom: '2px',
            },
          }}
          onClose={hideModal}
        >
          <HeaderDialog>
            <Stack>
              <TitleDialog>Account Settings</TitleDialog>
            </Stack>
            <IconButton aria-label="close" onClick={hideModal}>
              <CloseOutlined />
            </IconButton>
          </HeaderDialog>
          <DialogContent
            style={{
              padding: '0',
              height: 'auto',
              overflow: 'auto',
            }}
          >
            <div>{getClientSetting()}</div>
          </DialogContent>
        </Dialog>
      ) : (
        <Modal
          disableClickout
          size="xs"
          title="Account Settings"
          wrapperBackground={true}
          onRequestClose={hideModal}
        >
          {getClientSetting()}
        </Modal>
      )}
    </>
  );
};

export default UpdateClientModalRevamp;
