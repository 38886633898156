import styled from '@/styles';

export const CountPill = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '4px',
  background: '#fff',
  padding: '8px 10px',
  borderRadius: '17px',
  fontSize: '12px',
  flex: '0 0 auto',
  '@phoneOnly': {
    padding: '4px 10px',
  },
});

export const IconButtonMenu = styled('button', {
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'transparent',
  border: 'none',
});

export const HeaderDialog = styled('span', {
  padding: '10px 20px 15px 25px',
  background: 'linear-gradient(180deg, #EEFFEC 38.16%, #FFF 107.94%)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const TitleDialog = styled('span', {
  color: '#45A735',
  fontSize: '16px',
  fontWeight: '600',
});

export const MenuList = styled('ul', {
  background: '#FFF',
  '@phoneOnly': {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
});

export const MenuItemRight = styled('li', {
  padding: '12px 20px',
  fontSize: '14px',
  fontWeight: '400',
  cursor: 'pointer',
  '@phoneOnly': {
    padding: '0',
    fontSize: '16px',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    svg: {
      minWidth: '20px',
    },
  },
  variants: {
    disabled: {
      true: {
        color: '#C4C4C4',
      },
      false: {
        color: '#262626',
      },
    },
  },
});

export const ProfileCardWrap = styled('ul', {
  background: 'transparent',
  listStyle: 'none',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
  '@phoneOnly': {},
});

export const ProfileCard = styled('li', {
  borderRadius: '12px',
  background: '#FFF',
  boxShadow: '0px 2px 3.9px 0px rgba(92, 92, 92, 0.25)',
  '@phoneOnly': {},
});

export const NoWrapSpan = styled('span', {
  whiteSpace: 'nowrap',
});
