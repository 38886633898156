import { FC, ReactNode } from 'react';

import { useAppSelector, useAppDispatch } from '@/hooks/store';
import {
  selectSidebarActive,
  setSidebarVisibility,
} from '@/store/slices/layoutSlice';
import styled from '@/styles';
import { isWeb } from '@/util/platform';

const NAVBAR_HEIGHT = '49px';
const SIDEBAR_WIDTH = '256px';
const SIDEBAR_COLLAPSED_WIDTH = '68px';

const Wrapper = styled('main', {
  display: 'grid',
  gridTemplateAreas: '"navbar" "content" "footer"',
  gridTemplateColumns: '100vw',
  gridTemplateRows: `${NAVBAR_HEIGHT} 1fr auto`,
  height: '100%',
  padding:
    'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left) !important;',

  '@smallDesktop': {
    gridTemplateAreas:
      '"navbar navbar" "sidebar content" "sidebar footer" "bottomnavbar bottomnavbar"',
    gridTemplateColumns: `${SIDEBAR_WIDTH} 1fr`,
    gridTemplateRows: `${NAVBAR_HEIGHT} 1fr auto`,
  },

  variants: {
    isCollapsed: {
      true: {
        '@smallDesktop': {
          gridTemplateColumns: `${SIDEBAR_COLLAPSED_WIDTH} 1fr`,
        },
      },
    },
  },
  '@phoneOnly': {
    paddingBottom: ' 0 !important',
  },
});

const NavbarContainer = styled('nav', {
  display: 'flex',
  gridArea: 'navbar',
  zIndex: 3000,

  '& > *': {
    flex: 1,
  },

  '@smallDesktop': {
    height: NAVBAR_HEIGHT,
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
  },
});

const SidebarContainer = styled('aside', {
  boxShadow: '$shadows$2',
  bottom: 0,
  left: 0,
  position: 'fixed',
  top: '65px',
  overflowY: 'auto',
  zIndex: 3000,
  height: isWeb() ? `100vh` : `calc(100vh - 115px)`,

  '& > *': {
    flex: 1,
  },
});

const TouchableContainer = styled('div', {
  width: SIDEBAR_WIDTH,
  transition: 'width 0.2s ease',
  backgroundColor: 'white',
  boxShadow: '0 1px 2px 0 $colors$neutralA12',
  bottom: 0,
  left: 0,
  position: 'fixed',
  top: NAVBAR_HEIGHT,
  overflowY: 'auto',
  zIndex: 3000,
  height: isWeb() ? `100vh` : `calc(100vh - 115px)`,

  '& > *': {
    flex: 1,
  },

  '@smallDesktop': {
    display: 'flex',
  },

  variants: {
    isCollapsed: {
      true: {
        width: SIDEBAR_COLLAPSED_WIDTH,
        overflow: 'visible',
        '& > *': {
          overflow: 'visible',
        },
      },
    },
  },
});

const BottomNavContainer = styled('div', {
  display: 'flex',
  position: 'sticky',
  bottom: 0,
  zIndex: 3000,
  backgroundColor: 'white',
  padding: '8px 10px',
  boxShadow: '0px -1px 8px rgba(0, 0, 0, 0.12)',

  '& > *': {
    flex: 1,
  },
});

const Backdrop = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 2500,
  opacity: 0,
  visibility: 'hidden',
  transition: 'opacity 0.2s ease, visibility 0.2s ease',

  variants: {
    show: {
      true: {
        opacity: 1,
        visibility: 'visible',
      },
    },
  },

  '@smallDesktop': {
    display: 'none',
  },
});

type Props = {
  Footer: ReactNode;
  Navbar: ReactNode;
  Sidebar: ReactNode;
  BottomNav: ReactNode;
  isSmallDesktop: boolean;
  children: ReactNode;
  isCollapsed?: boolean;
};

const AppFrameV2: FC<Props> = ({
  children,
  Footer,
  Navbar,
  Sidebar,
  BottomNav,
  isSmallDesktop,
  isCollapsed,
}) => {
  const showSidebar = useAppSelector((state) => selectSidebarActive(state));
  const dispatch = useAppDispatch();

  // Add touch handling
  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isSmallDesktop && showSidebar) {
      dispatch(setSidebarVisibility(false));
    }
  };

  return (
    <Wrapper isCollapsed={isCollapsed}>
      <Backdrop
        show={showSidebar && !isSmallDesktop}
        onClick={() => dispatch(setSidebarVisibility(false))}
      />
      <NavbarContainer>{Navbar}</NavbarContainer>

      {showSidebar && (
        <SidebarContainer
          onClick={(e) => {
            if (e.target === e.currentTarget && !isSmallDesktop) {
              dispatch(setSidebarVisibility(false));
            }
          }}
          onTouchMove={handleTouchMove}
        >
          <TouchableContainer
            isCollapsed={isCollapsed}
            onClick={(e) => e.stopPropagation()}
            onTouchMove={(e) => e.stopPropagation()} // Prevent sidebar from closing when scrolling content
          >
            {Sidebar}
          </TouchableContainer>
        </SidebarContainer>
      )}

      <section style={{ gridArea: 'content' }}>{children}</section>
      <footer style={{ gridArea: 'footer' }}>{Footer}</footer>
      {BottomNav && (
        <BottomNavContainer
          id="mobile-sticky-footer"
          style={{ gridArea: !isSmallDesktop ? 'auto' : 'bottomnavbar' }}
        >
          {BottomNav}
        </BottomNavContainer>
      )}
    </Wrapper>
  );
};

export { NAVBAR_HEIGHT, SIDEBAR_WIDTH, SIDEBAR_COLLAPSED_WIDTH };
export default AppFrameV2;
