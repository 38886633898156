import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import React, { useMemo, useState } from 'react';

import { SelectItem } from '..';
import JobBadge from '../../Job/JobBadge';
import { getJobStatusByJob, JobStatus } from '../../Job/util';
import { disableCheckbox } from '../util';

import Avatar from '@/components/Avatar';
import DropDownBadge from '@/components/DropDownBadge';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import TextStack from '@/components/TextStack';
import { Body, Subheading } from '@/components/Typography';
import Link from '@/elements/Link';
import { TableCell, TableRow } from '@/elements/Table';
import Text from '@/elements/Text';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import { Role } from '@/routes/PrivateRoute';
import ROUTES from '@/routes/routes';
import { OrderJobItem } from '@/types';
import { Scalars } from '@/types/graphql';
import { getHiredWorkersByJobWorkers } from '@/util/job';
import { formatISO } from '@/util/date';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { useFeatureValue } from '@growthbook/growthbook-react';

type Props = {
  job: OrderJobItem;
  onSelect?: (selectItem: SelectItem) => Scalars['Void'];
  isSelected?: Scalars['Boolean'];
  disableSelect?: Scalars['Boolean'];
  columnHeaders: string[];
  currentAdminIsCustomerAdmin: Scalars['Boolean'];
};

const OrderJobTableItem = ({
  job,
  isSelected = false,
  onSelect,
  disableSelect,
  columnHeaders,
}: Props) => {
  const [isDropdownOpen, setIsDropdown] = useState<boolean>(false);
  const jobStatus = getJobStatusByJob(job);
  const jobPublish = job.published;
  const { currentAdmin, currentAdminIsCustomerAdmin } = useAuth();
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const needsApprovalStatus = useFeatureValue(
    FEATURE_TOGGLE.DashboardApprovalStatusFeature,
    false,
  );

  const timesheetScrutiny = useFeatureValue(
    FEATURE_TOGGLE.TimesheetScrutiny,
    false,
  );

  const getJobStatus = () => {
    if (needsApprovalStatus) {
      if (jobStatus === JobStatus.COMPLETED && job.needsApproval) {
        return JobStatus.NEEDS_APPROVAL;
      }
      return jobStatus;
    } else if (jobPublish && jobStatus === JobStatus.COMPLETED) {
      return jobStatus;
    }
    return JobStatus.UNPUBLISHED;
  };

  const jobStatusBadge = useMemo(() => {
    if (timesheetScrutiny) {
      if (!currentAdminIsCustomerAdmin && job.needsReview) {
        return JobStatus.NEEDS_REVIEW;
      }
      return getJobStatus();
    }
    return getJobStatus();
  }, [
    job,
    needsApprovalStatus,
    timesheetScrutiny,
    currentAdminIsCustomerAdmin,
    jobPublish,
    jobStatus,
  ]);

  const getJobStatusCancelLink = useMemo(() => {
    if (!currentAdminIsCustomerAdmin) {
      if (
        job.cancelUser?.role === Role.CUSTOMER_ADMIN &&
        job.cancelUser?.__typename === 'CustomerAdmin'
      ) {
        return `../../${ROUTES.clients}/${job.cancelUser?.customer.id}/admin/${job.cancelUser?.id}`;
      } else {
        return '';
      }
    } else {
      if (job.cancelUser?.role === Role.CUSTOMER_ADMIN) {
        if (job.cancelUser?.user.id === currentAdmin?.user.id) {
          return `../../${ROUTES.myProfile}`;
        } else {
          return `../../${ROUTES.myTeam}/${job.cancelUser?.id}`;
        }
      } else {
        return '';
      }
    }
  }, []);

  const getColumnTitle = (rowIndex) => {
    if (columnHeaders.length > 0 && rowIndex < columnHeaders.length) {
      return columnHeaders[rowIndex];
    }

    return '';
  };

  const isDisabled = disableCheckbox(jobStatus, job.firstShiftStartAt, job);
  const startDate = formatISO(
    job.firstShiftStartAt,
    'PP',
    job.address.timezone,
  );
  const endDate = formatISO(job.lastShiftEndAt, 'PP', job.address.timezone);
  const jobInvites = job.jobOffers.filter((item) => item.acceptedAt == null);

  return (
    <React.Fragment key={job.id}>
      <TableRow
        mobile={phoneOnly}
        spacing={phoneOnly ? 'extraloose' : false}
        touchable="true"
        onClick={() => {
          if (!isDisabled) {
            onSelect?.({
              ...job,
              jobWorkers: getHiredWorkersByJobWorkers(job.jobWorkers as any),
              jobOffers: jobInvites,
              jurisdiction: job.address.state,
              label: `${format(
                new Date(job.firstShiftStartAt),
                'PP',
              )} - ${format(new Date(job.lastShiftEndAt), 'PP')}`,
            });
          }
        }}
      >
        {!disableSelect && (
          <TableCell
            align="center"
            checkboxCell={phoneOnly}
            data-celltitle={''}
            noCellTitle={phoneOnly}
            stack={phoneOnly}
          >
            <Stack align="center">
              <Option
                appearance="checkbox"
                checked={isSelected}
                disabled={isDisabled}
                id={`job-${job.id}`}
                label=""
                type="radio"
                onChange={() => null}
                onClick={() =>
                  onSelect?.({
                    ...job,
                    jobWorkers: getHiredWorkersByJobWorkers(
                      job.jobWorkers as any,
                    ),
                    jobOffers: jobInvites,
                    jurisdiction: job.address.state,
                    label: `${format(
                      new Date(job.firstShiftStartAt),
                      'PP',
                    )} - ${format(new Date(job.lastShiftEndAt), 'PP')}`,
                  })
                }
              />
            </Stack>
          </TableCell>
        )}

        <TableCell
          data-celltitle={getColumnTitle(0)}
          noCellTitle={phoneOnly}
          stack={phoneOnly}
        >
          <Stack gap={12}>
            <Avatar size={!phoneOnly ? 'xs' : 'sm'} src={job.skill.imageUrl} />
            <Link id={`job-link-${job.id}`} to={`jobs/${job.id}`}>
              {job.skill.name}
            </Link>
          </Stack>
        </TableCell>
        <TableCell data-celltitle={getColumnTitle(1)} stack={phoneOnly}>
          {startDate !== endDate ? ` ${startDate} - ${endDate}` : startDate}
        </TableCell>
        <TableCell
          css={{ minWidth: '154px' }}
          data-celltitle={getColumnTitle(2).name}
          stack={phoneOnly}
        >
          <Body>
            {formatISO(job.firstShiftStartAt, 'hh:mmaaa', job.address.timezone)}{' '}
            - {formatISO(job.lastShiftEndAt, 'hh:mmaaa', job.address.timezone)}
          </Body>
          <Body color={'lighter'}>{`${job.mandatoryBreakTime} min break`}</Body>
        </TableCell>
        <TableCell
          css={{ minWidth: '163px' }}
          data-celltitle={getColumnTitle(3)}
          stack={phoneOnly}
        >
          <Text>
            {`${job.hiredWorkersCount < 0 ? 0 : job.hiredWorkersCount}/${
              job.quantity
            }`}{' '}
            hired | {jobInvites.length} invited
          </Text>
        </TableCell>
        <TableCell
          css={{ width: '140px' }}
          data-celltitle={getColumnTitle(4)}
          stack={phoneOnly}
        >
          {jobStatus === JobStatus.CANCELLED && job.reason !== null ? (
            <DropDownBadge
              a11yLabel="actions"
              icon={isDropdownOpen ? faChevronUp : faChevronDown}
              label={'Cancelled'}
              size="sm"
              status="danger"
              onClick={() => setIsDropdown(!isDropdownOpen)}
            />
          ) : (
            <JobBadge jobStatus={jobStatusBadge} style={null} />
          )}
        </TableCell>
      </TableRow>

      {isDropdownOpen &&
        jobStatus === JobStatus.CANCELLED &&
        job.cancelledAt &&
        job.cancelUser && (
          <TableRow key={job.id}>
            <TableCell colSpan={5}>
              <Stack align="center" gap={40}>
                <Stack inline vertical align="start">
                  <Subheading>Cancelled by</Subheading>
                  <Stack gap={24}>
                    <div>
                      <Avatar
                        firstName={job.cancelUser.user.firstName}
                        size="md"
                        src={job.cancelUser.avatarUrl}
                      />
                    </div>
                    <TextStack>
                      <Link to={getJobStatusCancelLink}>
                        <Body weight="medium">
                          {`${job.cancelUser.user.firstName} ${job.cancelUser.user.lastName}`}
                        </Body>
                      </Link>
                      <Body>
                        {format(new Date(job.cancelledAt), 'MM/dd/yyyy')}
                      </Body>
                    </TextStack>
                  </Stack>
                </Stack>
                <Stack vertical align="start">
                  <Subheading>Notes</Subheading>
                  <TextStack>
                    <Body>{job.reason}</Body>
                  </TextStack>
                </Stack>
              </Stack>
            </TableCell>
          </TableRow>
        )}
    </React.Fragment>
  );
};

export default OrderJobTableItem;
