import {
  faFileAlt,
  faSortNumericDown,
  faSortNumericUpAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Snackbar } from '@mui/material';
import { parseISO } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import InvoiceBadge from './InvoiceBadge';

import Card from '@/components/Card';
import EmptyState from '@/components/EmptyState';
import IconicButton from '@/components/IconicButton';
import LoadingState from '@/components/LoadingState';
import Page from '@/components/Page';
import Paginator from '@/components/Paginator/Paginator';
import Stack from '@/components/Stack';
import Table from '@/components/Table';
import TextInput from '@/components/TextInput';
import { Small } from '@/components/Typography';
import { TIME_TO_REFRESH } from '@/constants/general';
import Icon from '@/elements/Icon';
import Link from '@/elements/Link';
import { TableCell, TableHead, TableHeading, TableRow } from '@/elements/Table';
import { useListInvoicesQuery } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useFilters from '@/hooks/useFilters';
import useMediaQuery from '@/hooks/useMediaQuery';
import {
  InvoiceFilterSetInput,
  InvoiceSortKeyEnum,
  StripeInvoiceStatusEnum,
} from '@/types/graphql';
import { parseTimestamp } from '@/util/date';
import { formatDateSimple } from '@/util/datetime';
import { centsToCurrency } from '@/util/number';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { getInvoiceIdentifier } from './util';

type SortByFilter = {
  sort: InvoiceSortKeyEnum;
  reverse: boolean;
};

const initialFilters: InvoiceFilterSetInput = {};

const Invoices = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(
    Number(searchParams.get('page') || 1),
  );
  const [itemsPerPage, setItemsPerPage] = useState(
    Number(searchParams.get('items') || 10),
  );

  const [toastMsg, setToastMsg] = useState<string | null>(null);

  const { currentAdminIsCustomerAdmin } = useAuth();
  const navigate = useNavigate();

  const [sortBy, setSortBy] = useState<SortByFilter>({
    sort:
      (searchParams.get('sort') as InvoiceSortKeyEnum) || InvoiceSortKeyEnum.ID,
    reverse: searchParams.get('reverse') !== 'false',
  });
  const { reverse, sort } = sortBy;

  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const {
    debouncedQuery,
    filters,
    query: searchQuery,
    setQuery,
  } = useFilters<any>(initialFilters, searchParams.get('search') || '');

  const finalFilters = useMemo<InvoiceFilterSetInput>(() => {
    const value = { ...filters };

    if (debouncedQuery !== '') {
      value.query = { value: debouncedQuery };
    }
    return value;
  }, [debouncedQuery, filters]);

  const sorting = (value: SortByFilter['sort']) => {
    navigate({
      search: getQuery({
        sortCol: value,
        sortDirection: sortBy.sort === value ? !sortBy.reverse : true,
      }),
    });
  };

  const query = useListInvoicesQuery({
    pollInterval: TIME_TO_REFRESH,
    fetchPolicy: 'network-only',
    variables: {
      page: currentPage,
      perPage: itemsPerPage,
      filters: finalFilters,
      sortKey: sort,
      reverse,
    },
  });

  const handlePageChange = (pageNumber: number) => {
    navigate({
      search: getQuery({
        page: pageNumber,
      }),
    });
  };
  const handleNumberItemsChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    navigate({
      search: getQuery({
        page: 1,
        items: parseInt(event.target.value),
      }),
    });
  };

  const invoices =
    !query.loading && query.data ? query.data.listStripeInvoices.items : [];
  const pageInfo =
    !query.loading && query.data ? query.data.listStripeInvoices.pageInfo : {};

  const sortIcon = (col: InvoiceSortKeyEnum) => (
    <Icon
      fixedWidth
      icon={reverse && sort === col ? faSortNumericUpAlt : faSortNumericDown}
      size={'sm'}
    />
  );
  const getQuery = ({
    page = currentPage,
    items = itemsPerPage,
    sortCol = sort,
    sortDirection = reverse,
    search = searchQuery,
  }) =>
    `?page=${page}&items=${items}${
      search ? `&search=${search}` : ''
    }&sort=${sortCol}&reverse=${sortDirection}`;

  useEffect(() => {
    const params: any = {};
    searchParams.forEach((val, key) => {
      params[key] = val;
    });

    if (location.state && location.state?.invalidInvoice) {
      setToastMsg('Invoice is either deleted or voided');
    }

    if (Object.keys(params).length === 0) {
      navigate({ search: getQuery({}) }, { replace: true });
      return;
    }

    setCurrentPage(Number(params.page || currentPage));
    setItemsPerPage(Number(params.items || itemsPerPage));
    setSortBy((prev) => ({
      sort: params.sort || prev.sort,
      reverse: params.reverse !== 'false',
    }));
    setQuery(params.search || '');
  }, [searchParams]);

  const refiningInvoiceNumber = useFeatureValue(
    FEATURE_TOGGLE.RefiningInvoiceNumber,
    false,
  );

  return (
    <Page
      headerPadding={!!phoneOnly}
      noPadding={!!phoneOnly}
      size={phoneOnly ? 'full' : 'xl'}
      title="Invoices"
    >
      <Card noRadius={phoneOnly}>
        {!currentAdminIsCustomerAdmin && (
          <Card.Section>
            <div style={{ flex: 1 }}>
              <TextInput
                id="input-search-invoices"
                placeholder="Search invoices"
                value={searchParams.get('search') || ''}
                onChange={(ev) => {
                  navigate({
                    search: getQuery({
                      page: 1,
                      search: encodeURIComponent(ev.target.value),
                    }),
                  });
                }}
              />
            </div>
          </Card.Section>
        )}
        <Table>
          {!phoneOnly && (
            <TableHead>
              <TableRow>
                <TableHeading
                  id="table-invoiceid"
                  touchable="true"
                  onClick={() => sorting(InvoiceSortKeyEnum.ID)}
                >
                  {`Invoice ID `}
                  {sortIcon(InvoiceSortKeyEnum.ID)}
                </TableHeading>
                {!currentAdminIsCustomerAdmin && (
                  <TableHeading
                    id="table-client"
                    touchable="true"
                    onClick={() => sorting(InvoiceSortKeyEnum.CUSTOMER_NAME)}
                  >
                    {`Client `}
                    {sortIcon(InvoiceSortKeyEnum.CUSTOMER_NAME)}
                  </TableHeading>
                )}
                <TableHeading
                  id="table-billing-account"
                  touchable="true"
                  onClick={() => sorting(InvoiceSortKeyEnum.BILLING_ACCOUNT)}
                >
                  {`Billing Account `}
                  {sortIcon(InvoiceSortKeyEnum.BILLING_ACCOUNT)}
                </TableHeading>
                <TableHeading>Amount</TableHeading>
                <TableHeading
                  id="table-createdon"
                  touchable="true"
                  onClick={() => sorting(InvoiceSortKeyEnum.CREATED_ON)}
                >
                  {`Created on `}
                  {sortIcon(InvoiceSortKeyEnum.CREATED_ON)}
                </TableHeading>
                <TableHeading
                  id="table-due"
                  touchable="true"
                  onClick={() => sorting(InvoiceSortKeyEnum.DUE_DATE)}
                >
                  {`Due `}
                  {sortIcon(InvoiceSortKeyEnum.DUE_DATE)}
                </TableHeading>
                <TableHeading
                  align="center"
                  id="table-invoice-status"
                  touchable="true"
                  onClick={() => sorting(InvoiceSortKeyEnum.STATUS)}
                >
                  {`Invoice Status `}
                  {sortIcon(InvoiceSortKeyEnum.STATUS)}
                </TableHeading>
                <TableHeading align="center">Action</TableHeading>
              </TableRow>
            </TableHead>
          )}
          <tbody>
            {invoices.map((invoice) => {
              const total = centsToCurrency(Number(invoice.total));
              const formattedCreatedDate = formatDateSimple(
                parseISO(invoice.createdDatetime),
              );
              const dueDate = parseTimestamp(invoice.dueDate ?? '');
              const formattedDueDate = formatDateSimple(parseISO(dueDate));
              const invoiceIsDraft =
                invoice.status === StripeInvoiceStatusEnum.DRAFT;

              return (
                <TableRow
                  key={`invoice-${invoice.id}`}
                  clickable
                  id={`invoice-${invoice.id}`}
                  mobile={phoneOnly}
                  onClick={() =>
                    navigate(`${invoice.number || invoice.id}${getQuery({})}`)
                  }
                >
                  <TableCell noCellTitle stack={phoneOnly}>
                    <Stack
                      align={'start'}
                      gap={1}
                      justify={'start'}
                      vertical={!phoneOnly}
                    >
                      <Link id={`link-invoice-${invoice.id}`} to={``}>
                        {phoneOnly && (
                          <Small size={'lg'} weight={'semibold'}>
                            Invoice ID:{' '}
                          </Small>
                        )}
                        <Small
                          color={'theme'}
                          size={phoneOnly ? 'lg' : 'md'}
                          weight={phoneOnly ? 'semibold' : 'normal'}
                        >
                          {`#${getInvoiceIdentifier(refiningInvoiceNumber, invoice)}`}
                        </Small>
                      </Link>
                    </Stack>
                  </TableCell>
                  {!currentAdminIsCustomerAdmin && (
                    <TableCell data-celltitle={'Client: '} stack={phoneOnly}>
                      {invoice.customer.name}
                    </TableCell>
                  )}
                  <TableCell
                    data-celltitle={'Billing Account: '}
                    stack={phoneOnly}
                  >
                    {invoice.account?.name}
                  </TableCell>
                  <TableCell data-celltitle={'Amount: '} stack={phoneOnly}>
                    {total} USD
                  </TableCell>
                  <TableCell data-celltitle={'Created on: '} stack={phoneOnly}>
                    {formattedCreatedDate}
                  </TableCell>
                  <TableCell data-celltitle={'Due Date: '} stack={phoneOnly}>
                    {invoice.dueDateDatetime && formattedDueDate}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={invoice.status}
                    data-celltitle={'Invoice Status: '}
                    stack={phoneOnly}
                  >
                    <InvoiceBadge status={invoice.status} />
                  </TableCell>
                  <TableCell
                    align="center"
                    data-celltitle={'Action: '}
                    id={`row-file-${invoice.id}`}
                    stack={phoneOnly}
                    onClick={(e) => {
                      invoiceIsDraft && e.stopPropagation();
                    }}
                  >
                    <IconicButton
                      a11yLabel="action"
                      appearance="clear"
                      disabled={invoiceIsDraft}
                      icon={faFileAlt}
                      id={`btn-file-${invoice.id}`}
                      onClick={(ev) => {
                        ev.stopPropagation();
                        navigate(`${invoice.id}/pay${getQuery({})}`);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
            {query.loading && (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={currentAdminIsCustomerAdmin ? 5 : 6}
                >
                  <LoadingState overlayColor="white" />
                </TableCell>
              </TableRow>
            )}
          </tbody>
        </Table>
        {!query.loading && invoices.length === 0 && (
          <EmptyState
            icon={faFileAlt}
            text="No invoices were found"
            title="No Invoices"
          />
        )}
      </Card>
      {invoices.length > 0 && (
        <Paginator
          currentPage={currentPage}
          handleNumberItemsChange={handleNumberItemsChange}
          handlePageChange={handlePageChange}
          itemsLength={pageInfo?.totalItems}
          itemsPerPage={itemsPerPage}
        />
      )}

      {toastMsg && (
        <Snackbar
          ContentProps={{
            sx: {
              background: '#c23636',
              color: '#FFDFD8',
            },
          }}
          autoHideDuration={3000}
          id="toast-msg"
          message={toastMsg}
          open={!!toastMsg}
          onClose={() => setToastMsg(null)}
        />
      )}
    </Page>
  );
};

export default Invoices;
