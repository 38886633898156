import { GetInvoiceDetailsRevampQuery } from '@/types/graphql';

type Invoice =
  GetInvoiceDetailsRevampQuery['listStripeInvoiceDetail']['invoice'];
export const getInvoiceIdentifier = (
  refiningInvoiceNumber: boolean,
  invoice: Invoice,
): string => {
  return refiningInvoiceNumber
    ? `${invoice.number || invoice.id}`
    : `${invoice.id}${invoice.number ? `-${invoice.number}` : ''}`;
};
