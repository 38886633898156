import styled from '@/styles';
import { SIDEBAR_WIDTH, NAVBAR_HEIGHT } from '@/components/AppFrame';

export const AgencyWrapper = styled('div', {
  padding: 20.5,
  width: '100%',

  borderBottom: '1px solid $neutralLightest',
});

export const AgencyAvatar = styled('div', {
  alignItems: 'center',
  boxSize: 36,
  display: 'flex',
  justifyContent: 'center',

  backgroundColor: '$neutralLighter',
  borderRadius: '$lg',
});

export const CollapseButton = styled('button', {
  position: 'fixed',
  left: `calc(${SIDEBAR_WIDTH} - 12px)`,
  top: `calc(${NAVBAR_HEIGHT} + 20px)`,
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  background: '$background',
  border: '1px solid $border',
  display: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: '$sidebar',
  padding: 0,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  transition: 'left 0.2s ease',

  '@smallDesktop': {
    display: 'flex',
  },

  '&:hover': {
    background: '$backgroundHover',
  },

  '& > div': {
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    border: '1px solid $border',
    background: 'white',
  },

  variants: {
    isCollapsed: {
      true: {
        left: '60px',
      },
    },
  },
});

export const TooltipWrapper = styled('div', {
  position: 'relative',
  display: 'inline-flex',

  '&:hover::before': {
    content: 'attr(data-tooltip)',
    position: 'absolute',
    left: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
    marginLeft: '12px',
    padding: '4px 8px',
    borderRadius: '4px',
    backgroundColor: '#1F2937',
    color: 'white',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    zIndex: 1000,
  },

  '&:hover::after': {
    content: '""',
    position: 'absolute',
    left: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
    marginLeft: '4px',
    border: '4px solid transparent',
    borderRightColor: '#1F2937',
  },
});

export const MenuItemText = styled('span', {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  transition: 'opacity 0.2s ease-out, width 0.2s ease-out',

  variants: {
    isCollapsed: {
      true: {
        opacity: 0,
        width: 0,
      },
      false: {
        opacity: 1,
        width: 'auto',
      },
    },
  },
});
