import Popover from '@mui/material/Popover';
import { format } from 'date-fns';

import { Body } from '@/components/Typography';
import Text from '@/elements/Text';

const rowStyle = {
  display: 'block',
  padding: '0px 12px 0px 16px',
};

const cellStyle = {
  color: 'black',
  lineHeight: 'normal',
  letterSpacing: '.17px',
  padding: '0px 0px',
};

const boldTextStyle = {
  color: 'black',
  lineHeight: 'normal',
  letterSpacing: '.17px',
  padding: '0px 4px 0px 0px',
  fontSize: 12,
  fontWeight: 700,
};

const textStyle = {
  color: 'black',
  lineHeight: 'normal',
  letterSpacing: '.17px',
  fontSize: 12,
  fontWeight: 400,
  width: '150px',
};

// Helper function to format date
const getJobTooltipFormattedDate = (dateStr: string) =>
  dateStr ? format(Date.parse(dateStr), 'MMM do - HH:mma') : '';

const InfoRow = ({
  label,
  value,
  bold = false,
}: {
  label: string;
  value?: string;
  bold?: boolean;
}) => (
  <div style={rowStyle}>
    <div style={cellStyle}>
      <Text css={bold ? boldTextStyle : textStyle} wordWrap="breakWord">
        {label}
      </Text>
      {value && (
        <Text css={textStyle} wordWrap="breakWord">
          {value}
        </Text>
      )}
    </div>
  </div>
);

const ReviewSection = ({ reason }: { reason: string }) => (
  <div style={{ padding: '12px 0px 20px 0px' }}>
    <InfoRow label={reason} bold />
  </div>
);

const DetailsSection = ({
  isNoShow,
  isDropped,
  eventDoneBy,
  eventDate,
  reason,
}: {
  isNoShow: boolean;
  isDropped: boolean;
  eventDoneBy: string;
  eventDate: string;
  reason: string;
}) => (
  <div style={{ padding: '12px 0px 20px 0px' }}>
    {!isDropped && (
      <InfoRow
        label={isNoShow ? 'Marked By:' : 'Removed By:'}
        value={eventDoneBy}
        bold
      />
    )}
    <InfoRow
      label="Date & Time:"
      value={getJobTooltipFormattedDate(eventDate)}
      bold
    />
    {!isNoShow && <InfoRow label="Reason:" value={reason} bold />}
  </div>
);

const CustomTooltipJob = ({
  open,
  anchorEl,
  handlePopoverClose,
  isDropped,
  isNoShow,
  reason,
  eventDate,
  eventDoneBy,
  needsReview = false,
}: any) => {
  return (
    <Popover
      disableRestoreFocus
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={open}
      slotProps={{
        paper: {
          style: {
            maxWidth: '269px',
            width: '269px',
            boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.12)',
            border: '1px solid #E2E2E2',
          },
          className: 'afterArrow',
        },
      }}
      sx={{
        pointerEvents: 'none',
        left: needsReview ? '110px !important' : '275px !important',
        top: '-50px',
      }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={handlePopoverClose}
    >
      <Body css={{ background: '#DFF3D8', padding: '12px 16px' }} weight="bold">
        Detail
      </Body>
      {needsReview ? (
        <ReviewSection reason={reason} />
      ) : (
        <DetailsSection
          {...{ isNoShow, isDropped, eventDoneBy, eventDate, reason }}
        />
      )}
    </Popover>
  );
};

export default CustomTooltipJob;
