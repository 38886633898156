import { createSvgIcon } from '@mui/material';

const InvoiceIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fontSize: '1.25rem' }}
  >
    <path
      d="M12.25 8V15.5M14.5 9.034C13.3703 8.74307 12.1949 8.67413 11.039 8.831C10.503 8.903 10.065 9.309 10.018 9.848C10.0061 9.98167 10.0001 10.1158 10 10.25C10 10.714 10.336 11.094 10.775 11.244L13.725 12.256C14.165 12.406 14.5 12.786 14.5 13.25C14.5 13.386 14.494 13.52 14.482 13.652C14.435 14.191 13.997 14.597 13.461 14.669C12.305 14.8243 11.1299 14.7554 10 14.466M19.5 4.75657V21.7496L15.75 20.2496L12 21.7496L8.25 20.2496L4.5 21.7496V4.75657C4.5 3.64857 5.306 2.69957 6.407 2.57157C10.1232 2.14021 13.8768 2.14021 17.593 2.57157C18.693 2.69957 19.5 3.64857 19.5 4.75657Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'Invoice',
);

export default InvoiceIcon;
