import { Stack, Dialog, DialogContent } from '@mui/material';
import { HeaderDialog, TitleDialog } from '@/components/Modal';
import Button from '@/components/Button';

const ScrutinyDialogPhoneView = ({
  headerText,
  infoText,
  open,
  onClose,
}: {
  headerText: string;
  infoText: string;
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <Dialog
      PaperProps={{
        style: {
          maxHeight: '280px',
          borderRadius: '15px',
        },
      }}
      aria-labelledby="second-options-dialog"
      fullScreen={true}
      open={open}
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'end',
          marginBottom: '2px',
        },
      }}
      onClose={onClose}
    >
      <HeaderDialog>
        <Stack>
          <TitleDialog>{headerText}</TitleDialog>
        </Stack>
      </HeaderDialog>
      <DialogContent
        style={{
          padding: '20px',
          height: 'auto',
          overflow: 'auto',
        }}
      >
        <div style={{ height: '150px', padding: '0 0 20px' }}>{infoText}</div>
        <Button
          appearance="outline"
          a11yLabel="Got it"
          id="btn-got-it"
          label="Got it"
          style={{ width: '100%' }}
          type="reset"
          onClick={onClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ScrutinyDialogPhoneView;
