import {
  faCalendarWeek,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { VariantProps, CSS } from '@stitches/react';
import { format, isToday } from 'date-fns';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

import Stack from '@/components/Stack';
import Tag from '@/components/Tag';
import TextStack from '@/components/TextStack';
import { Body, Small } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import Icon from '@/elements/Icon';
import useMediaQuery from '@/hooks/useMediaQuery';
import styled from '@/styles';
import colors from '@/styles/colors';
import { JobTypeEnum, ListShiftsByWeekQuery } from '@/types/graphql';
import { formatISO, getTimezoneAbbreviation } from '@/util/date';
import {
  calculateTotalHoursInShift,
  getTotalHoursShiftLabel,
  sortShifts,
} from '@/util/job';
import TZBadge from '@/components/TZBadge';
import useAuth from '@/hooks/useAuth';

const DateHeading = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  padding: '0.75rem 0',
  width: '100%',
  borderBottom: '1px solid $colors$neutralLighter',

  '@phoneOnly': {
    justifyContent: 'flex-start',
    borderBottom: 0,
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
    borderTop: '1px solid $colors$neutralLighter',
  },
});

const ShiftCardWrapper = styled('div', {
  width: '100%',
  padding: '0 0.25rem',
  '@phoneOnly': {
    padding: 0,
  },
});

const ShiftCard = styled(Link, {
  $$bgColor: '$colors$themeA16',
  $$borderColor: '$colors$themeDarkest',
  $$color: '$colors$themeDarkest',
  $$hoverBgColor: '$colors$themeA24',

  display: 'block',
  padding: '0.25rem 0.5rem',

  backgroundColor: '$$bgColor',
  borderRadius: '$lg',
  borderTop: '3px solid $$borderColor',
  color: '$$color',

  textDecoration: 'none',

  '&:hover': {
    backgroundColor: '$$hoverBgColor',
  },

  '@phoneOnly': {
    borderTop: 0,
    borderLeft: '4px solid $$borderColor',
    padding: '0.75rem',
    borderRadius: '$xxl',
  },

  variants: {
    status: {
      filled: {},
      unfilled: {
        $$bgColor: '$colors$warningA24',
        $$borderColor: '$colors$warningDarkest',
        $$color: '$colors$textDefault',
        $$hoverBgColor: '$colors$warningA32',
      },
      unpublished: {
        $$bgColor: '$colors$dangerA12',
        $$color: '$colors$dangerDarker',
        $$borderColor: '$colors$dangerDarkest',
        $$hoverBgColor: '$colors$dangerA32',
      },
    },
  },
});
const TagColors = {
  filled: colors.themeDarker,
  unfilled: colors.warningDarker,
  unpublished: colors.dangerDarker,
  timesheetapproval: colors.needsApproval,
  timesheetReview: colors.needsReview,
};

type Props = {
  date: Date;
  shifts: ListShiftsByWeekQuery['agency']['shiftsByWeek'];
};

type ShiftStatus = VariantProps<typeof ShiftCard>['status'];

const ShiftsColumn = ({ date, shifts }: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const [showJob, setShowJob] = useState(isToday(date));
  const showDashboardApprovalStatus = useFeatureValue(
    FEATURE_TOGGLE.DashboardApprovalStatusFeature,
    false,
  );

  const dashboardMobileFriendlyNavigation = useFeatureValue(
    FEATURE_TOGGLE.DashboardMobileFriendlyNavigation,
    false,
  );

  const handleDateHeadingClick = () => {
    setShowJob(!showJob);
  };

  const activeJob = () => {
    if (phoneOnly) {
      return showJob;
    }
    return true;
  };

  return (
    <div
      style={
        phoneOnly
          ? {
              minWidth: '145px',
              paddingBottom: 0,
            }
          : {
              paddingBottom: '8px',
              minWidth: '164px',
            }
      }
    >
      <Stack vertical gap={4}>
        {phoneOnly ? (
          <DateHeading onClick={handleDateHeadingClick}>
            <Body
              color={showJob || activeJob() ? 'theme' : 'neutral'}
              css={{
                width: '100%',
              }}
              size="xl"
              weight="extraBold"
            >
              {!dashboardMobileFriendlyNavigation && (
                <Stack justify="apart">
                  <Stack gap={8}>
                    <Icon fixedWidth icon={faCalendarWeek} />
                    <span>{format(date, 'eee M/d')}</span>
                  </Stack>
                  {showDashboardApprovalStatus &&
                    shifts?.filter((shift) => shift.job.needsApproval)?.length >
                      0 && (
                      <Small>
                        <Tag
                          label="Needs Approval"
                          style={{
                            minHeight: '0.5rem !important',
                            backgroundColor: TagColors.timesheetapproval,
                            color: 'white',
                          }}
                        />
                      </Small>
                    )}
                  <Icon
                    fixedWidth
                    icon={showJob ? faChevronUp : faChevronDown}
                    id="toggle-btn"
                  />
                </Stack>
              )}
            </Body>
          </DateHeading>
        ) : (
          <DateHeading>
            <Body color="default" size="md" weight="semibold">
              {format(date, 'eee M/d')}
            </Body>
          </DateHeading>
        )}
        {shifts.length > 0 ? (
          <Stack
            vertical
            css={{ display: phoneOnly ? (showJob ? 'flex' : 'none') : 'flex' }}
            gap={phoneOnly ? 8 : 4}
          >
            <ShiftCardList shifts={shifts} />
          </Stack>
        ) : (
          <Stack className="no-shifts-here">
            <div className="no-shifts-here-logo">
              <img src={require('@/assets/icons/noShiftHereLogo.png')} />
            </div>
            <div className="no-shifts-here-heading">No Shifts Here</div>
            <div className="no-shifts-here-content">
              You don't have any shifts on this day.
            </div>
          </Stack>
        )}
      </Stack>
    </div>
  );
};

const ShiftCardList = ({ shifts }: Pick<Props, 'shifts'>) => {
  const orderedShifts = shifts.sort(sortShifts);
  const showDashboardApprovalStatus = useFeatureValue(
    FEATURE_TOGGLE.DashboardApprovalStatusFeature,
    false,
  );
  const timesheetScrutiny = useFeatureValue(
    FEATURE_TOGGLE.TimesheetScrutiny,
    false,
  );

  const { currentAdminIsCustomerAdmin } = useAuth();

  const needApprovalOrReviewChip = (shift) => {
    const getTag = (label, color) => (
      <Small>
        <Tag
          label={label}
          style={{
            minHeight: '0.5rem !important',
            backgroundColor: color,
            color: 'white',
          }}
        />
      </Small>
    );

    const needsReview = !currentAdminIsCustomerAdmin && shift.job.needsReview;
    const needsApproval = shift.job.needsApproval;

    if (timesheetScrutiny && needsReview) {
      return getTag('Needs Review', TagColors.timesheetReview);
    }

    if (showDashboardApprovalStatus && needsApproval) {
      return getTag('Needs Approval', TagColors.timesheetapproval);
    }

    return <></>;
  };

  return (
    <Fragment>
      {orderedShifts.map((shift, index) => {
        let shiftStatus: ShiftStatus = 'filled';

        if (shift.job.hiredWorkersCount < shift.job.quantity) {
          if (shift.job.published) {
            shiftStatus = 'unfilled';
          } else {
            shiftStatus = 'unpublished';
          }
        } else {
          shiftStatus = 'filled';
        }
        const destination = `orders/${shift.job.order.id}/jobs/${shift.job.id}`;
        const totalHoursInShift = calculateTotalHoursInShift(shift, true);
        const tz = getTimezoneAbbreviation(
          shift.job.address.timezone,
          shift.startAt,
        );
        return (
          <ShiftCardWrapper key={index}>
            <ShiftCard
              key={shift.id}
              id={`shift-${shift.id}`}
              status={shiftStatus}
              to={destination}
            >
              <TextStack>
                <Body
                  color="inherit"
                  weight="medium"
                  className="dashboardCustomerName"
                >
                  {shift.job.account.customer.name}
                </Body>
                <Small color="default">{shift.job.skill.name}</Small>
                <Small color="default">
                  {shift.job.address.addressLine1}, {shift.job.address.city},{' '}
                  {shift.job.address.state} {shift.job.address.zip}
                </Small>
                <Small color="default">
                  {formatISO(shift.startAt, 'p', shift.job.address.timezone)}{' '}
                  &mdash;{' '}
                  {formatISO(shift.endAt, 'p', shift.job.address.timezone)}
                </Small>
                <TZBadge
                  timezone={shift.job.address.timezone}
                  date={shift.startAt}
                  locale={false}
                />
                <Small
                  color="default"
                  weight={totalHoursInShift > 8 ? 'bold' : 'normal'}
                >
                  {getTotalHoursShiftLabel(totalHoursInShift)}
                </Small>
                <Small color="default">
                  {shift.job.hiredWorkersCount} / {shift.job.quantity} hired
                </Small>
                {shift.job.jobType === JobTypeEnum.LTA && (
                  <Small>
                    <Tag
                      label="LTA"
                      style={{
                        minHeight: '0.5rem !important',
                        backgroundColor: TagColors[shiftStatus],
                        color: 'white',
                      }}
                    />
                  </Small>
                )}
                {needApprovalOrReviewChip(shift)}
              </TextStack>
            </ShiftCard>
          </ShiftCardWrapper>
        );
      })}
    </Fragment>
  );
};

export default ShiftsColumn;
