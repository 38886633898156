import { object, string } from 'yup';

import { REGEX } from '@/constants/regex';
import { capitalizeFirstLetter } from '@/util/text';

const transformValue = (value: string) => capitalizeFirstLetter(value);

const accountNameSchema = string()
  .trim()
  .required('Account Name is a required field')
  .min(2, 'Account Name must be at least 2 characters');

const baseSchema = {
  defaultContact: object().required('Primary Contact is a required field'),
  address: object({
    addressLine1: string()
      .trim()
      .required('Address Line is a required field')
      .transform(transformValue),
    addressLine2: string().trim(),
    city: string()
      .trim()
      .required('City is a required field')
      .matches(
        REGEX.CITY_REGEX,
        'Only alphabetic characters are allowed for this field',
      )
      .transform(transformValue),
    zip: string()
      .trim()
      .max(5, 'Zip Code must be no longer than 5 characters')
      .required('Zip Code is a required field'),
    state: string()
      .trim()
      .required('State is a required field')
      .min(2, 'State must be at least 2 characters')
      .max(2, 'State must be no longer than 2 characters'),
  }).required('Address is a required field'),
};

export const getSchemaByContext = (context: 'ADD' | 'EDIT' = 'ADD') => {
  const schemaByContext = {
    ADD: {
      accountName: accountNameSchema,
      ...baseSchema,
    },
    EDIT: baseSchema,
  }[context];

  return object(schemaByContext).required();
};
