import { createSvgIcon } from '@mui/material';

const JobMonitorIcon = createSvgIcon(
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fontSize: '1.3rem' }}
  >
    <path
      d="M21 13.0176C21 14.1995 20.7672 15.3698 20.3149 16.4617C19.8626 17.5537 19.1997 18.5458 18.364 19.3815C17.5282 20.2173 16.5361 20.8802 15.4442 21.3325C14.3522 21.7848 13.1819 22.0176 12 22.0176C10.8181 22.0176 9.64778 21.7848 8.55585 21.3325C7.46392 20.8802 6.47177 20.2173 5.63604 19.3815C4.80031 18.5458 4.13738 17.5537 3.68508 16.4617C3.23279 15.3698 3 14.1995 3 13.0176C3 10.6306 3.94821 8.34144 5.63604 6.65362C7.32387 4.96579 9.61305 4.01758 12 4.01758C14.3869 4.01758 16.6761 4.96579 18.364 6.65362C20.0518 8.34144 21 10.6306 21 13.0176Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 12.5176H6.5L8.5 16.5176L12.5 9.01758L14.5 14.0176H18.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 5.51758L19.5 7.01758"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 2.01758H14"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'JobMonitor',
);

export default JobMonitorIcon;
