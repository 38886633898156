import { Link } from 'react-router-dom';

import { IS_QA } from '@/constants/env';
import styled from '@/styles';

export const Wrapper = styled('div', {
  alignItems: 'center',
  display: 'flex',
  height: 48,
  padding: '0 24px',
  backgroundColor: IS_QA ? '$dangerDefault' : 'white',
  boxShadow: '0 0 2px 2px $colors$neutralA12',
  touchAction: 'none',
  WebkitOverflowScrolling: 'none',
});

export const BurgerMenu = styled('button', {
  alignItems: 'center',
  appearance: 'none',
  backgroundColor: 'transparent',
  border: 'none',
  color: '#1a1a1a',
  cursor: 'pointer',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  padding: '0 16px',
  marginLeft: '-16px',

  '& > div > svg': {
    width: '24px',
    height: '24px',
  },

  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },

  '@media (min-width: 900px)': {
    display: 'none',
  },
});

export const Branding = styled(Link, {
  alignItems: 'center',
  color: '#1a1a1a',
  display: 'flex',
  height: '100%',
  padding: '6px 0',

  '@desktop': {
    marginLeft: 0,
  },
});

export const DropdownWrapper = styled('div', {
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
  marginLeft: 'auto',
});

export const Profile = styled('div', {
  alignItems: 'center',
  color: '#1a1a1a',
  cursor: 'pointer',
  display: 'flex',
  gap: '6px',
  borderRadius: '4px',
  backgroundColor: '#F3F4F6',

  variants: {
    isOpen: {
      true: {
        border: '2px solid #ADDB9B',
      },
      false: {
        border: '2px solid transparent',
      },
    },
  },

  defaultVariants: {
    isOpen: false,
  },
});

export const BellWrapper = styled('div', {
  cursor: 'pointer',
  padding: '8px',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
});

export const RedDot = styled('div', {
  position: 'absolute',
  top: '4px',
  right: '4px',
  width: '8px',
  height: '8px',
  backgroundColor: '#EF4444',
  borderRadius: '50%',
});

export const AvatarWrapper = styled('div', {});

export const ProfileField = styled('div', {
  width: '128px',

  overflow: 'hidden',

  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  variants: {
    light: {
      true: {
        color: '$neutralA76',

        fontSize: '$sm',
        lineHeight: '14px',
      },
      false: {
        color: '#1a1a1a',

        fontSize: '$md',
        lineHeight: '16px',
      },
    },
  },
});

export const NameContainer = styled('div', {
  display: 'flex',
  '@phoneOnly': {
    display: 'none',
  },
});

export const Logo = styled('img', {
  height: '24px',

  '@phoneOnly': {
    display: 'none',
  },
});

export const LeftSection = styled('div', {
  display: 'flex',
  alignItems: 'center',

  '@desktop': {
    display: 'none',
  },
});

Wrapper.displayName = 'stitches(Navbar.Wrapper)';
Branding.displayName = 'stitches(Navbar.Branding)';
Profile.displayName = 'stitches(Navbar.Profile)';
