import { createSvgIcon } from '@mui/material';

const PayrollRunsIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fontSize: '1.3rem' }}
  >
    <path
      d="M6 16.5H13.5M16.5 7.5H19.875C20.496 7.5 21 8.004 21 8.625V18C21 18.5967 20.7629 19.169 20.341 19.591C19.919 20.0129 19.3467 20.25 18.75 20.25M16.5 7.5V18C16.5 18.5967 16.7371 19.169 17.159 19.591C17.581 20.0129 18.1533 20.25 18.75 20.25M16.5 7.5V4.875C16.5 4.254 15.996 3.75 15.375 3.75H4.125C3.504 3.75 3 4.254 3 4.875V18C3 18.5967 3.23705 19.169 3.65901 19.591C4.08097 20.0129 4.65326 20.25 5.25 20.25H18.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.25 6V13.5M11.5 7.034C10.3703 6.74307 9.19494 6.67413 8.039 6.831C7.503 6.903 7.065 7.309 7.018 7.848C7.00609 7.98167 7.00008 8.1158 7 8.25C7 8.714 7.336 9.094 7.775 9.244L10.725 10.256C11.165 10.406 11.5 10.786 11.5 11.25C11.5 11.386 11.494 11.52 11.482 11.652C11.435 12.191 10.997 12.597 10.461 12.669C9.30501 12.8243 8.12991 12.7554 7 12.466"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'PayrollRuns',
);

export default PayrollRunsIcon;
