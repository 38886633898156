import { FC, ComponentType, SVGProps } from 'react';
import styled from '@/styles';

const IconWrapper = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  color: 'inherit',
  lineHeight: 'inherit',

  '& > svg': {
    width: '20px',
    height: '20px',
  },

  variants: {
    size: {
      xs: {
        '& > svg': {
          width: '16px',
          height: '16px',
        },
      },
    },
    theme: {
      default: { color: 'inherit' },
      success: { color: '$successTick' },
      information: { color: '#ECA300' },
      danger: { color: '$dangerDarker' },
      neutral: { color: '$neutralLight' },
      theme: { color: '$colors$themeDefault' },
      lighter: { color: '$textLighter' },
      white: { color: '$whiteA96' },
    },
  },
  defaultVariants: {
    theme: 'default',
  },
});

export interface HeroIconProps {
  icon: ComponentType<SVGProps<SVGSVGElement>>;
  size?: 'xs';
  theme?:
    | 'default'
    | 'success'
    | 'information'
    | 'danger'
    | 'neutral'
    | 'theme'
    | 'lighter'
    | 'white';
}

const HeroIcon: FC<HeroIconProps> = ({ icon: Icon, size, theme }) => {
  return (
    <IconWrapper size={size} theme={theme}>
      <Icon />
    </IconWrapper>
  );
};

HeroIcon.displayName = 'HeroIcon';

export default HeroIcon;
