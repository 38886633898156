import { createSvgIcon } from '@mui/material';

const InvoiceGeneratorIcon = createSvgIcon(
  <svg
    fill="none"
    height="24"
    viewBox="0 0 23 24"
    width="23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M4.5 12H13.5V15H4.5V12ZM13.0828 0.328125L17.6719 4.92188C17.8828 5.13281 18 5.41875 18 5.71406V6H12V0H12.2859C12.5859 0 12.8719 0.117188 13.0828 0.328125ZM10.5 0V6.375C10.5 6.99375 11.0063 7.5 11.625 7.5H18V15C16.9064 15 15.8811 15.2926 14.9981 15.8038C14.9994 15.786 15 15.7681 15 15.75V11.25C15 10.8356 14.6644 10.5 14.25 10.5H3.75C3.33562 10.5 3 10.8356 3 11.25V15.75C3 16.1644 3.33562 16.5 3.75 16.5H14.0313C13.1457 17.2817 12.4926 18.3207 12.189 19.5H10.875C10.6678 19.5 10.5 19.6678 10.5 19.875V20.625C10.5 20.8322 10.6678 21 10.875 21H12C12 22.0929 12.2922 23.1175 12.8027 24H1.125C0.501562 24 0 23.4984 0 22.875V1.125C0 0.501562 0.501562 0 1.125 0H10.5ZM3.375 3C3.16781 3 3 3.16781 3 3.375V4.125C3 4.33219 3.16781 4.5 3.375 4.5H7.125C7.33219 4.5 7.5 4.33219 7.5 4.125V3.375C7.5 3.16781 7.33219 3 7.125 3H3.375ZM3.375 6C3.16781 6 3 6.16781 3 6.375V7.125C3 7.33219 3.16781 7.5 3.375 7.5H7.125C7.33219 7.5 7.5 7.33219 7.5 7.125V6.375C7.5 6.16781 7.33219 6 7.125 6H3.375Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M15.3712 19.4283C15.217 19.2784 15.016 19.197 14.8087 19.2006C14.6013 19.2041 14.4029 19.2923 14.2532 19.4475C14.1034 19.6026 14.0135 19.8132 14.0014 20.0371C13.9893 20.2609 14.0559 20.4815 14.1879 20.6544L16.0121 22.7246C16.0885 22.8112 16.1802 22.8802 16.2819 22.9276C16.3836 22.9749 16.493 22.9995 16.6037 23C16.7138 23.0007 16.8229 22.9774 16.9246 22.9317C17.0262 22.8859 17.1183 22.8185 17.1953 22.7334L22.7665 16.5141C22.842 16.4301 22.9015 16.3309 22.9416 16.222C22.9816 16.1131 23.0014 15.9968 22.9999 15.8796C22.9984 15.7625 22.9755 15.6468 22.9327 15.5392C22.8898 15.4316 22.8277 15.3342 22.75 15.2525C22.6724 15.1708 22.5805 15.1065 22.4799 15.0632C22.3792 15.0199 22.2716 14.9984 22.1632 15.0001C22.0549 15.0017 21.9479 15.0264 21.8484 15.0728C21.7489 15.1192 21.6588 15.1863 21.5832 15.2703L16.6119 20.8321L15.3712 19.4283Z"
      fill="currentColor"
    />
  </svg>,
  'InvoiceGeneratorIcon',
);
export default InvoiceGeneratorIcon;
